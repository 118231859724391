import minutes0 from '../assets/minutes/minutes-0.mp4'
import minutes1 from '../assets/minutes/minutes-1.png'
// import minutes2 from '../assets/minutes/minutes-2.png'
import minutes3 from '../assets/minutes/minutes-3.png'
// import panel from '../assets/minutes/panel.png'
import inapp from '../assets/minutes/inapp.png'
import upload from '../assets/minutes/upload.png'
import conference from '../assets/minutes/conference.png'
import Button from '../components/ui/Button'
import mainExperience from '../assets/minutes/mainexperience.mp4'
import word from '../assets/minutes/word.mp4'
import clip from '../assets/minutes/clip.mp4'
import mobileview from '../assets/minutes/mobileview.mp4'
import adminview from '../assets/minutes/adminview.png'
import mobilerecord from '../assets/minutes/mobilerecord.mp4'
import inlineClip from '../assets/minutes/inlineclip.mp4'
import browserView from '../assets/minutes/browser-view.png'

import clipB from '../assets/minutes/b-clip.mp4';
import editB from '../assets/minutes/b-edit.mp4';
import recordB from '../assets/minutes/b-record.mp4';
import viewB from '../assets/minutes/b-view.mp4';


import Caption from '../components/ui/Caption'
import InlineLink from '../components/ui/InlineLink'
import '../App.css'

import { useState } from 'react';

const Minutes = () => {
    const [slide, setSlide] = useState(0)

    const browserList1 = ['View', 'Clip', 'Edit', 'Record']
    const browserList2 = [viewB, clipB, editB, recordB]

    const setSlideHandler = (p) => {
        setSlide(p);
    }

    const slideList = browserList1.map((item, index) => {
        if (index === slide) {
            return (
                <div className='slider-item slider-selected' onClick={() => { setSlideHandler(index) }} key={Math.random()}>{item}</div>
            )
        } else {
            return (
                <div className='slider-item' onClick={() => { setSlideHandler(index) }} key={Math.random()}>{item}</div>
            )
        }
    })

    let slideVideo;

    for (let i = 0; i < browserList2.length; i++) {
        if (i === slide) {
            slideVideo = (
                <video width="1200" height="720" loop autoPlay muted className='s-1 r-8 slidein-animation' key={Math.random()}>
                    <source src={browserList2[i]} type="video/mp4" />
                </video>
            )
        }
    }

    return (
        <div className='project-container'>
            <div className='project-hero background-g1'>
                <div className='h1 m-top-64'>Bytedance Mniutes</div>
                <div className='p1 text-grey m-top-16 con-960 text-center'>Smart video transcription tool for meetings, interviews, conferences and other important voice conversations.</div>
                <Button className='m-top-16' href='https://bytedance.feishu.cn/minutes/'>Go to Minutes</Button>
                <video width="1200" height="720" className='m-top-48 m-bottom-64 s-1 r-8' loop autoPlay muted>
                    <source src={minutes0} type="video/mp4" />
                </video>
            </div>
            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>A snapshot</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                    <div className='con-516'>
                        <Caption className='m-top-24'>My role</Caption>
                        <div className='h4 m-top-16'>In charge of overall product design</div>
                        <div className='p2 text-grey m-top-8'>As the product design lead, incubated the product from 0 to 1 and continued leading design crafts in interations.</div>
                        <div className='h4 m-top-16'>Lead a team of 3 designers</div>
                        <div className='p2 text-grey m-top-8'>Responsible for design management from 0 to 3 designers, design strategy, roadmap and design review.</div>
                    </div>
                    <div className='con-516'>
                        <Caption className='m-top-24'>Product milestones</Caption>
                        <div className='h4 m-top-16'>Product launched on Bytedance Lark conference</div>
                        <div className='p2 text-grey m-top-8'>As one of featured products, Bytedance Minutes was luanched in Nov 2020 on Bytedance Lark conference.</div>
                        <div className='h4 m-top-16'>Overachieved product growth</div>
                        <div className='p2 text-grey m-top-8'>Product grew from thousands of beta users to over half a million MAU in 2021.</div>
                    </div>
                    {/* <img src={minutes2} style={{width:'913px'}} className='m-top-48'></img> */}
                    {/* <div className='p2 text-grey m-top-24' style={{fontStyle:'italic'}}>(Lark is a team collaboration suite for business groups, Minutes is one pillar in Lark Suite.)</div> */}
                </div>
            </div>
            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Why Minutes</div>
            </div>
            <div className='con-1176 m-auto m-top-48 flex-between'>
                <div className='background-g2 con-576' style={{ padding: '48px' }}>
                    <img src={minutes1} style={{ width: '480px' }}></img>
                </div>
                <div className='background-g2 con-576' style={{ padding: '48px', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '2px', height: '397px', backgroundColor: '#e4e4e4' }}></div>
                    <div style={{ width: '454px' }}>
                        <div style={{ width: '100%' }} className='p2'>“Keep a copy of the meeting and why we made the conclusion, in case I want to recap”</div>
                        <div style={{ width: '100%' }} className='p2 text-grey'>—— Project manager</div>
                        <div style={{ width: '100%' }} className='p2 m-top-24'>“Summarize take aways after meeting with clients”</div>
                        <div style={{ width: '100%' }} className='p2 text-grey'>—— Marketing</div>
                        <div style={{ width: '100%' }} className='p2 m-top-24'>“Transcribe important meetings to sections and quotes, so that people can refer to”</div>
                        <div style={{ width: '100%' }} className='p2 text-grey'>—— CEO assistant</div>
                        <div style={{ width: '100%' }} className='p2 m-top-24'>“I manually transcribe company training materials”</div>
                        <div style={{ width: '100%' }} className='p2 text-grey'>—— Human resource </div>
                        <div style={{ width: '100%' }} className='p2 m-top-24'>...</div>
                    </div>
                </div>
            </div>
            <div className='m-top-144 fullsize-greytowhite'>
                <div className='con-1080 m-auto'>
                    <div className='h1'>Video & audio Transcription</div>
                    <div className='con-720 text-grey p2 m-top-24'>Smart video transcription tool for meetings, interviews, conferences and other important voice conversations.</div>
                    <img src={minutes3} style={{ height: '504px' }} className='m-top-48'></img>
                </div>
            </div>
            <div className='m-top-144 con-1080 m-auto'>
                <div className='h3'>End to end experience within Lark</div>
                <div className='con-720 text-grey p2 m-top-16'>The MVP of Minutes starts with in Lark suite experience. When the user finished recording a meeting, Lark will send an in app message with the meeting Minutes. It gives user a seamless experience when they needs to record & transcribe an important meeting.</div>
            </div>
            <div style={{ width: '100%', height: '796px', position: 'relative', paddingTop: '16px', overflow: 'hidden' }} className='background-g1 m-top-48'>
                <div className='m-auto con-1200'>
                    <video width="1200" height="832" loop autoPlay muted>
                        <source src={mainExperience} type="video/mp4" />
                    </video>
                </div>
                {/* <img src={panel} style={{height:'72px', position:'absolute', bottom:'8px', left:'50%', transform:'translateX(-50%)'}}></img> */}
            </div>
            <div className='con-1176 m-auto m-top-144 flex-between'>
                <div className='background-g1 con-576' style={{ paddingLeft: '48px', paddingTop: '48px', overflow: 'hidden' }}>
                    <div className='h3'>From meeting record</div>
                    <div className='text-grey p2 m-top-16 m-right-48'>One way for user to generate a Minutes file is to through in Lark suite meeting record. It serves a great in app experience for Lark suite and provides Minutes with a solid user base.</div>
                    <img src={inapp} style={{ width: '528px', borderTopLeftRadius: '8px' }} className='m-top-24 s-1'></img>
                </div>
                <div className='background-g1 con-576' style={{ paddingRight: '48px', paddingTop: '48px', overflow: 'hidden' }}>
                    <div className='h3 m-left-48'>From upload & record</div>
                    <div className='text-grey p2 m-top-16 m-left-48'>The other way to generate a Minutes file is to directly record or upload in Minutes app. It scales Minutes to none Lark users, and non meetings scenarios.<br /><br /></div>
                    <img src={upload} style={{ width: '528px', borderTopRightRadius: '8px' }} className='m-top-24 s-1'></img>
                </div>
            </div>
            {/* <div className='m-top-144'>
                <div className='con-1080 m-auto'>
                    <div className='h1'>The user interface</div>
                    <div className='con-720 p2 m-top-24 text-grey'>The Minutes app can be mainly divided into two major surfaces:
                    <br />Detail — where you view, edit and share your Minutes.
                    <br />Browser — where you manage your files, projects and account.</div>
                </div>
            </div> */}
            {/* <div className='m-top-48 con-1080 m-auto'>
                <div className='h3'>Detail view</div>
                <div className='con-720 text-grey p2 m-top-16'>The detail view is where users spend most of their time view, edit and share with others. It is consisted of two parts with video on the left and transcription on the right, so that the user gets a view of video + text.</div>
                <div className='p2 text-grey m-top-16 m-bottom-16' style={{ fontStyle: 'italic' }}>(The embeded Figma below may take a while to load)</div>
            </div> */}

            <div className='h1 con-720 m-auto text-center m-top-144'>The user interface</div>
            <div className='p2 con-720 m-auto m-top-16 text-grey'><span className='default-color'>Detail view - </span>where users spend most of their time view, edit and share with others. It is consisted of two parts with video on the left and transcription on the right, so that the user gets a view of video + text.</div>

            <div className='slider m-top-48'>
                {slideList}
            </div>

            <div style={{ height: '720px' }} className='con-1200 m-auto'>
                {slideVideo}
            </div>

            {/* <div style={{ position: 'relative'}} className='con-1200 m-auto r-8'>
                <iframe width="1201" height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FP0cNhtNEg2YzEYkLk0iInR%2FPrototypes%3Fpage-id%3D184%253A16303%26node-id%3D184%253A17840%26viewport%3D245%252C48%252C0.27%26scaling%3Dmin-zoom%26starting-point-node-id%3D184%253A17840%26hide-ui%3D1" allowfullscreen className='s-1 r-8' style={{ border: 'none'}}></iframe>
                <div style={{ position: 'absolute', height: '700px', width: '1201px', border: '1px solid #d4d4d4', zIndex: '1', top: '0px', left: '0px', pointerEvents: 'none'}} className='r-8'></div>
            </div> */}

            <div className='p2 con-720 m-auto m-top-16 text-grey m-top-144'><span className='default-color'>Browser view - </span>The browser view is probably easier to relate to as it follows a more traditional master-detail layout - master list on the left, detail view for selected section on the right.</div>

            <img src={browserView} className='con-1200 m-auto m-top-48 s-1 r-8' loading='lazy'></img>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>At Bytedance press release</div>
                <div className='m-top-48' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='con-516'>
                        <div className='p2 text-grey m-top-8'>Lark Minutes was launched at Bytedance Conference in 2020 as one of the featured products for Lark suite.</div>
                        <div className='p2 text-grey m-top-16'><span className='default-color'>Brand value - </span>Lark branded itself as a next-gen team collaboration suite. Minutes served as a cutting edge product for integrating video and text information that greatly increases company's efficiency.</div>
                        <div className='p2 text-grey m-top-16'><span className='default-color'>User acquisition - </span>For Minutes, this is a great opportunity for the product to go get users and go viral.</div>
                    </div>
                    <img src={conference} className='con-516'></img>
                </div>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Product development</div>
            </div>

            <div className='con-1176 m-top-48 flex-between m-auto'>
                <div style={{ width: '384px', paddingLeft: '48px' }}>
                    <div className='h3'>Improve accuracy</div>
                    <div className='p2 m-top-16 text-grey'><span className='default-color'>Recognize terms - </span>Minutes integrates with company vocabulary to recognize words that are hard to transcribe such as professional terminologies and people’s name etc.</div>
                    <div className='p2 m-top-16 text-grey'><span className='default-color'>Remove colloquial language - </span>colloquial terms are usually not helpful at all when it comes to reading. Minutes remove these terms automatically for a greater reading efficiency.</div>

                </div>
                <div style={{overflow:'hidden', width: '768px', paddingTop: '48px', paddingRight: '48px', background: 'linear-gradient(75.22deg, rgba(47, 106, 241, 0.75) -0.66%, rgba(47, 106, 241, 0.35) 94.54%)', boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.08)' }}>
                    <div className='s-2' style={{ width: '720px', height: '384px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                        <video width="700" height="360" loop autoPlay muted>
                            <source src={word} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>

            <div className='con-1176 m-top-144 flex-between m-auto'>
                <div style={{ width: '384px', paddingLeft: '48px' }}>
                    <div className='h3'>Share effectiveness</div>
                    <div className='p2 m-top-16 text-grey'><span className='default-color'>Clip - </span>Minutes are usually generated from meetings, interviews that easily go beyond an hour. With video clip, the user can highlight the most important information and share that with others.</div>
                    <div className='p2 m-top-16 text-grey'><span className='default-color'>Inline view - </span>with clip feature, there are a lot more scenarios users insert a link of Minutes clip. The inline view allows user to consume Minutes in documents, chats instantly.</div>
                </div>
                <div style={{overflow:'hidden', width: '768px', paddingTop: '48px', paddingLeft: '48px', backgroundColor:'#E9C273', boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.08)'}}>
                    <video width="672" height="500" loop autoPlay muted className='s-2' style={{borderTopLeftRadius:'12px', borderTopRightRadius:'12px', display:'block'}}>
                        <source src={inlineClip} type="video/mp4" />
                    </video>
                </div>
            </div>

            {/* <div className='con-1080 m-top-144 m-auto'>
                <div className='h3'>Mobile experience</div>
                <div className='h4 m-top-16'>Consume from anywhere</div>
                <div className='con-720 text-grey p2 m-top-8'>Users take most of complex actions on desktop, the mobile app provides user with the quick access to consume content.</div>
                <div className='h4 m-top-16'>Record on the go</div>
                <div className='con-720 text-grey p2 m-top-8'>With record on mobile, users can leave other record devices but only take the phone when they go to an interview, meeting etc.</div>
            </div> */}

            <div className='h1 con-720 m-auto text-center m-top-144'>Mobile experience</div>
            <div className='p2 con-720 m-auto m-top-16 text-grey'><span className='default-color'>Consume & record from anywhere - </span>users take most of complex actions on desktop, while the mobile app provides user with the quick access to consume and record at anytime. The design optimizes for mobile scenario and interaction, it reserves as much space as possible for content, also allows Minutes running in the background.</div>
            {/* <div className='p2 con-720 m-auto text-grey m-top-16'><span className='default-color'>Record on the go - </span>With record on mobile, users can leave other record devices but only take the phone when they go to an interview, meeting etc.</div>a */}


            <div style={{ width: '100%' }} className='background-g1 m-top-48' style={{ display: 'flex', justifyContent: 'center' }}>
                <video width="375" height="812" loop autoPlay muted className='m-top-48 s-1 m-bottom-48 m-right-96'>
                    <source src={mobileview} type="video/mp4" />
                </video>
                <video width="375" height="812" loop autoPlay muted className='m-top-48 s-1 m-bottom-48'>
                    <source src={mobilerecord} type="video/mp4" />
                </video>
            </div>

            <div className='con-1080 m-top-144 m-auto'>
                <div className='h1'>Data reference</div>
            </div>
            <div className='con-1176 m-top-48 m-auto flex-between'>
                <div>
                    <div className='data-card'>
                        <Caption>Mau</Caption>
                        <div className='m-top-24'><div className='data-number'>{">"} 500K</div></div>
                        <div className='p2 m-top-16 text-grey'>As the Northstar metrics, the monthly active user of Minutes grow from thousands of users to over 500k in the year of 2021.</div>
                    </div>
                    <div className='data-card' style={{ marginTop: '120px' }}>
                        <Caption>Record & upload</Caption>
                        <div className='m-top-24'><div className='data-number'>{">"} 35%</div></div>
                        <div className='p2 m-top-16 text-grey'>Over 35% of Minutes files are generated from Record & upload. It validates the form of video + transcription in multiple business scenarios.</div>
                    </div>
                </div>
                <div className='data-card' style={{ marginTop: '240px' }}>
                    <Caption>Adoption rate</Caption>
                    <div className='m-top-24'><div className='data-number'>{">"} 300%</div></div>
                    <div className='p2 m-top-16 text-grey'>The adoption rate of meeting record feature in Lark suite increased over 300%, after we launched the Minutes app.</div>
                </div>
            </div>

            <div className='con-1080 m-top-144 m-auto'>
                <div className='h1'>Commercialization</div>

                <div className='p2 con-720 m-top-16 text-grey'><span className='default-color'>Admin control - </span>admin feature gives our client access control on the company level. It is a premium feature for Minutes.</div>
                <div className='p2 con-720 text-grey m-top-16'><span className='default-color'>Storage plan - </span>on top of admin accessibility, Minutes also charge based on storage usage. It gives client different levels of solutions for different tiers.</div>
            </div>
            <img src={adminview} className='con-1200 m-auto m-top-48 s-1 r-8' loading='lazy'></img>

            <div className='con-1080 m-top-144 m-auto'>
                <div className='h1'>Minutes examples</div>
                <div className='p2 m-top-48'><span className='span-bold'>Meeting scenario: </span><InlineLink href='https://bytedance.feishu.cn/minutes/obcn8wc7w7jl7q5x6x2spfc2'>Design review meeting record</InlineLink></div>
                <div className='p2 m-top-16'><span className='span-bold'>Presentation scenario: </span><InlineLink href='https://bytedance.feishu.cn/minutes/obcn8w8141yz7j9a468k7qv9'>Figma schema - reimagining design system</InlineLink></div>
            </div>


        </div>
    )
}

export default Minutes;