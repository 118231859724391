import Button from '../components/ui/Button'
import { useEffect, useRef } from 'react'

const Load = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const size = 48*3;
        const canvasHeight = 480;
        const canvasWidth = 1080;
        let t = 0; // Time
        const a = 3; // a
        const b = 2; // b
        const r = size / 4; // radius in points
        const tau = 2 * Math.PI; // because Tau is greater than Pi
        const centerX = canvasWidth / 2;
        const centerY = canvasHeight / 2;
        const numberOfDots = 100; // sample (definition) of the curve. Higher numbers means shorter line between points, so smoother curve.

        canvas.width = canvasWidth * 2;
        canvas.height = canvasHeight * 2;
        canvas.style.width = canvasWidth + "px";
        canvas.style.height = canvasHeight + "px";

        const context = canvas.getContext("2d");
        context.scale(2, 2);


        function draw(timestamp) {
            context.save();
            context.clearRect(0, 0, canvasWidth, canvasHeight);

            // The Lissajous curve using the Constants defined above
            var x = Math.cos(a * t) * r + centerX;
            var y = Math.sin(b * t) * r + centerY;
            context.lineCap = "round";
            context.lineJoin = "round";
            // Except if it's the first point, draw a segment between the previous point and the current point 
            for (let i = 0; i <= numberOfDots; i++) {
                context.beginPath();
                let brightness = Math.round(255 - (i / numberOfDots) * 255);
                context.strokeStyle = "rgb(" + brightness + ", " + brightness + ", " + brightness + ")";
                context.lineWidth = (i / numberOfDots) * (size / 24);
                context.moveTo(x, y);
                
                if (i > 0) {
                    var offset = timestamp * 0.001;
                    t = timestamp * 0.0015 + (i / numberOfDots) * tau;
                    x = Math.cos(a * t + offset) * r + centerX;
                    y = Math.sin(b * t) * r + centerY;
                    context.lineTo(x, y);
                    context.stroke();
                }
            }

            context.restore();
            window.requestAnimationFrame(draw);
        }

        draw()
    }, [])

    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>'lissajous curve' inspired Loader</div>
            <div className='p2 m-top-24 con-720 text-center text-grey'>The design of loader was inpired by 'lissajous curve'. It integrates and prsents the beauty of mathmatics, while people are waiting for there content to load.</div>
            <Button className='m-top-24' href='https://codepen.io/menghuihu/pen/bxyzQV/7f246bee96b382e538ca6ec0ee657e80?editors=0010'>View on Codepen</Button>
            <canvas ref={canvasRef} style={{ backgroundColor: '#f4f4f4' }} className='m-top-48 r-48'></canvas>
        </div>
    )
}

export default Load;