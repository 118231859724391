import Tag from './ui/Tag'

const Article = (props) => {
    const tag = props.tag.map((tag)=>{
        return <Tag key={Math.random()} className='m-right-8'>{tag}</Tag>
    })

    return (
        <a className={`article-tile tile ${props.className}`} href={props.href} target='_blank'>
            <div>
                <div className='caption1 text-grey'>{props.caption}</div>
                <div className='h3 m-top-12 default-color'>{props.title}</div>
                <div className='p2 text-grey m-top-8' style={{fontFamily: 'charter, Georgia, Cambria, "Times New Roman", Times, serif'}}>{props.intro}</div>
            </div>
            <div className='default-color'>{tag}</div>
        </a>
    )
}

export default Article;