const Confettisvg = () => {
    return(
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 1440 850' style={{ enableBackground: 'new 0 0 1440 850' }}>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 28 0; 0 0' begin='0.720804366049s' dur='1.42867501261s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(98, 1350)' points='6.0,0.11 -3.09,5.14 -2.91,-5.25 6.0,0.11 '>
                <animateTransform id='rise0' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.720804366049s' begin='0s' from='0 850' to='98 220' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.324835726615s' begin='0.820804366049s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.324835726615s' begin='0.720804366049s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.63999922591s' begin='0.720804366049s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='98 220' to='98 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.86080359196s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1354, 1350)' points='3.96,-0.53 0.53,3.96 -3.96,0.53 -0.53,-3.96 '>
                <animateTransform id='rise1' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.589661314343s' begin='0s' from='1440 850' to='1354 126' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.94670882759s' begin='0.689661314343s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.94670882759s' begin='0.589661314343s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.98312140189s' begin='0.589661314343s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1354 126' to='1354 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.07278271624s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 25 0; 0 0' begin='0.568594372789s' dur='1.3521182108s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1125, 1350)' points='1.53,-1.29 0.35,1.97 -1.88,-0.68 1.53,-1.29 '>
                <animateTransform id='rise3' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.788327493403s' begin='0s' from='1440 850' to='1125 148' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.49833935233s' begin='0.888327493403s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.49833935233s' begin='0.788327493403s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.64677024695s' begin='0.788327493403s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1125 148' to='1125 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.93509774035s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 23 0; 0 0' begin='0.793413229107s' dur='1.16360030016s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(193, 1350)' points='4.94,0.75 -3.12,3.91 -1.82,-4.66 4.94,0.75 '>
                <animateTransform id='rise5' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.614506238833s' begin='0s' from='1440 850' to='193 74' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.0787399952s' begin='0.714506238833s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.0787399952s' begin='0.614506238833s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.77490799215s' begin='0.614506238833s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='193 74' to='193 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.88941423098s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 9 0; 0 0' begin='0.722744189712s' dur='1.76781864394s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1131, 1350)' points='-1.68,-1.09 1.09,-1.68 1.68,1.09 -1.09,1.68 '>
                <animateTransform id='rise6' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.722744189712s' begin='0s' from='0 850' to='1131 124' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.99408475901s' begin='0.822744189712s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.99408475901s' begin='0.722744189712s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.69809604573s' begin='0.722744189712s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1131 124' to='1131 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.92084023544s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 12 0; 0 0' begin='0.660052265119s' dur='1.96661736207s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(122, 1350)' points='-2.99,-2.65 3.8,-1.26 -0.8,3.92 -2.99,-2.65 '>
                <animateTransform id='rise8' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.660052265119s' begin='0s' from='0 850' to='122 269' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.50422958479s' begin='0.760052265119s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.50422958479s' begin='0.660052265119s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.51933433403s' begin='0.660052265119s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='122 269' to='122 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.67938659915s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(850, 1350)' points='2.78,-4.16 4.16,2.78 -2.78,4.16 -4.16,-2.78 '>
                <animateTransform id='rise9' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.722571948777s' begin='0s' from='1440 850' to='850 170' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.317150490151s' begin='0.822571948777s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.317150490151s' begin='0.722571948777s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.95944053347s' begin='0.722571948777s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='850 170' to='850 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.18201248225s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 8 0; 0 0' begin='0.557081253467s' dur='1.67075857642s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(648, 1350)' points='-0.83,1.82 -1.82,-0.83 0.83,-1.82 1.82,0.83 '>
                <animateTransform id='rise10' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.557081253467s' begin='0s' from='0 850' to='648 123' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.644407283895s' begin='0.657081253467s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.644407283895s' begin='0.557081253467s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.46537837379s' begin='0.557081253467s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='648 123' to='648 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.52245962725s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(359, 1350)' points='2.78,-4.16 4.16,2.78 -2.78,4.16 -4.16,-2.78 '>
                <animateTransform id='rise11' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.649706040161s' begin='0s' from='1440 850' to='359 71' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.00648242738s' begin='0.749706040161s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.00648242738s' begin='0.649706040161s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.59156705278s' begin='0.649706040161s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='359 71' to='359 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.74127309295s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 28 0; 0 0' begin='0.562517476545s' dur='1.29514913243s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(704, 1350)' points='6.92,1.05 -1.05,6.92 -6.92,-1.05 1.05,-6.92 '>
                <animateTransform id='rise12' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.562517476545s' begin='0s' from='0 850' to='704 270' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.19567323594s' begin='0.662517476545s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.19567323594s' begin='0.562517476545s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.20963701643s' begin='0.562517476545s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='704 270' to='704 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.27215449297s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <circle fill='#61CDFF' strokeWidth='0.7' transform='translate(1119, 1350)' r='7'>
                <animateTransform id='rise13' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.603867253673s' begin='0s' from='1440 850' to='1119 197' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.438870732558s' begin='0.703867253673s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.438870732558s' begin='0.603867253673s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.51479302018s' begin='0.603867253673s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1119 197' to='1119 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='3.61866027386s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 23 0; 0 0' begin='0.698129524345s' dur='1.43999501834s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1068, 1350)' points='-2.0,2.24 -2.24,-2.0 2.0,-2.24 2.24,2.0 '>
                <animateTransform id='rise14' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.698129524345s' begin='0s' from='0 850' to='1068 265' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.40586446979s' begin='0.798129524345s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.40586446979s' begin='0.698129524345s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.59402917355s' begin='0.698129524345s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1068 265' to='1068 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.79215869789s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 8 0; 0 0' begin='0.756330115762s' dur='1.27547820434s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1319, 1350)' points='2.55,-5.43 3.43,4.92 -5.98,0.51 2.55,-5.43 '>
                <animateTransform id='rise16' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.756330115762s' begin='0s' from='0 850' to='1319 248' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.28853338681s' begin='0.856330115762s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.28853338681s' begin='0.756330115762s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.67719017909s' begin='0.756330115762s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1319 248' to='1319 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.93352029485s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(317, 1350)' points='4.78,1.48 -1.48,4.78 -4.78,-1.48 1.48,-4.78 '>
                <animateTransform id='rise17' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.508289131598s' begin='0s' from='1440 850' to='317 158' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.47861287927s' begin='0.608289131598s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.47861287927s' begin='0.508289131598s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.47830945915s' begin='0.508289131598s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='317 158' to='317 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.48659859075s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 25 0; 0 0' begin='0.646377513416s' dur='1.53600681856s' repeatCount='indefinite' />
            <circle fill='#61CDFF' strokeWidth='0.7' transform='translate(408, 1350)' r='3'>
                <animateTransform id='rise18' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.646377513416s' begin='0s' from='0 850' to='408 20' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.931176530984s' begin='0.746377513416s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.931176530984s' begin='0.646377513416s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.78164575911s' begin='0.646377513416s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='408 20' to='408 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='3.92802327253s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 12 0; 0 0' begin='0.521328096441s' dur='1.91598009289s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1436, 1350)' points='2.64,-3.0 1.28,3.79 -3.92,-0.79 2.64,-3.0 '>
                <animateTransform id='rise20' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.521328096441s' begin='0s' from='0 850' to='1436 189' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.358215369862s' begin='0.621328096441s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.358215369862s' begin='0.521328096441s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.57913109239s' begin='0.521328096441s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1436 189' to='1436 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.60045918883s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <circle fill='#FFB853' strokeWidth='0.7' transform='translate(950, 1350)' r='3'>
                <animateTransform id='rise21' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.798781833289s' begin='0s' from='1440 850' to='950 257' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.98666120697s' begin='0.898781833289s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.98666120697s' begin='0.798781833289s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.48697760021s' begin='0.798781833289s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='950 257' to='950 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='2.7857594335s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 18 0; 0 0' begin='0.67896457867s' dur='1.36959515933s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(622, 1350)' points='1.62,2.52 -3.0,0.14 1.38,-2.67 1.62,2.52 '>
                <animateTransform id='rise23' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.67218745865s' begin='0s' from='1440 850' to='622 226' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.02233585047s' begin='0.77218745865s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.02233585047s' begin='0.67218745865s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.48985761357s' begin='0.67218745865s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='622 226' to='622 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.66204507222s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 18 0; 0 0' begin='0.576613855739s' dur='1.93365929267s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(1228, 1350)' points='2.74,-1.21 1.21,2.74 -2.74,1.21 -1.21,-2.74 '>
                <animateTransform id='rise24' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.576613855739s' begin='0s' from='0 850' to='1228 114' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.61709821211s' begin='0.676613855739s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.61709821211s' begin='0.576613855739s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.44928261251s' begin='0.576613855739s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1228 114' to='1228 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.52589646825s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(242, 1350)' points='0.02,-4.0 4.0,0.02 -0.02,4.0 -4.0,-0.02 '>
                <animateTransform id='rise25' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.590281402557s' begin='0s' from='1440 850' to='242 272' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.7534584375s' begin='0.690281402557s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.7534584375s' begin='0.590281402557s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.63710591963s' begin='0.590281402557s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='242 272' to='242 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.72738732218s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 29 0; 0 0' begin='0.741228759593s' dur='1.04973573603s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1116, 1350)' points='6.67,4.41 -4.41,6.67 -6.67,-4.41 4.41,-6.67 '>
                <animateTransform id='rise26' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.741228759593s' begin='0s' from='0 850' to='1116 125' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.1445899807s' begin='0.841228759593s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.1445899807s' begin='0.741228759593s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.27123732217s' begin='0.741228759593s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1116 125' to='1116 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.51246608176s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1371, 1350)' points='-7.0,-0.06 0.06,-7.0 7.0,0.06 -0.06,7.0 '>
                <animateTransform id='rise27' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.609563691069s' begin='0s' from='1440 850' to='1371 249' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.99078197452s' begin='0.709563691069s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.99078197452s' begin='0.609563691069s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.64382249852s' begin='0.609563691069s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1371 249' to='1371 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.75338618959s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 29 0; 0 0' begin='0.512996872525s' dur='1.56543156417s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1242, 1350)' points='-0.38,-2.98 2.98,-0.38 0.38,2.98 -2.98,0.38 '>
                <animateTransform id='rise28' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.512996872525s' begin='0s' from='0 850' to='1242 61' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.04011597571s' begin='0.612996872525s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.04011597571s' begin='0.512996872525s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.64605301945s' begin='0.512996872525s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1242 61' to='1242 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.65904989198s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1403, 1350)' points='-8.0,-0.07 0.07,-8.0 8.0,0.07 -0.07,8.0 '>
                <animateTransform id='rise29' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.747474628213s' begin='0s' from='1440 850' to='1403 195' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.9207202988s' begin='0.847474628213s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.9207202988s' begin='0.747474628213s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.45653497473s' begin='0.747474628213s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1403 195' to='1403 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.70400960295s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 7 0; 0 0' begin='0.674270250258s' dur='1.78857630879s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(998, 1350)' points='1.7,-5.75 5.75,1.7 -1.7,5.75 -5.75,-1.7 '>
                <animateTransform id='rise30' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.674270250258s' begin='0s' from='0 850' to='998 40' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.295415009418s' begin='0.774270250258s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.295415009418s' begin='0.674270250258s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.87510495451s' begin='0.674270250258s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='998 40' to='998 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.04937520477s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 2 0; 0 0' begin='0.775621335968s' dur='1.97124409212s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1448, 1350)' points='-5.94,3.7 -0.24,-7.0 6.18,3.29 -5.94,3.7 '>
                <animateTransform id='rise32' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.775621335968s' begin='0s' from='0 850' to='1448 58' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.73528041066s' begin='0.875621335968s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.73528041066s' begin='0.775621335968s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.7432208434s' begin='0.775621335968s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1448 58' to='1448 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.01884217937s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1377, 1350)' points='5.95,-0.79 0.79,5.95 -5.95,0.79 -0.79,-5.95 '>
                <animateTransform id='rise33' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.553899589485s' begin='0s' from='1440 850' to='1377 253' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.47174237285s' begin='0.653899589485s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.47174237285s' begin='0.553899589485s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.3452088678s' begin='0.553899589485s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1377 253' to='1377 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.39910845729s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 28 0; 0 0' begin='0.688527848287s' dur='1.81078686073s' repeatCount='indefinite' />
            <circle fill='#F56681' strokeWidth='0.7' transform='translate(615, 1350)' r='7'>
                <animateTransform id='rise34' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.688527848287s' begin='0s' from='0 850' to='615 263' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.835733699935s' begin='0.788527848287s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.835733699935s' begin='0.688527848287s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.44239991896s' begin='0.688527848287s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='615 263' to='615 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='3.63092776725s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1248, 1350)' points='-1.98,0.25 -0.25,-1.98 1.98,-0.25 0.25,1.98 '>
                <animateTransform id='rise35' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.660362777662s' begin='0s' from='1440 850' to='1248 170' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.85982513264s' begin='0.760362777662s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.85982513264s' begin='0.660362777662s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.56926043402s' begin='0.660362777662s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1248 170' to='1248 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.72962321168s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 21 0; 0 0' begin='0.72710290505s' dur='1.33598081876s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(871, 1350)' points='-1.2,-2.75 2.75,-1.2 1.2,2.75 -2.75,1.2 '>
                <animateTransform id='rise36' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.72710290505s' begin='0s' from='0 850' to='871 279' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.05356195861s' begin='0.82710290505s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.05356195861s' begin='0.72710290505s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.17390518806s' begin='0.72710290505s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='871 279' to='871 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.40100809311s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(581, 1350)' points='0.53,1.93 -1.93,0.53 -0.53,-1.93 1.93,-0.53 '>
                <animateTransform id='rise37' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.678168418213s' begin='0s' from='1440 850' to='581 26' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.03345091356s' begin='0.778168418213s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.03345091356s' begin='0.678168418213s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.57740623002s' begin='0.678168418213s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='581 26' to='581 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.75557464823s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 31 0; 0 0' begin='0.547389255773s' dur='1.45632148113s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(56, 1350)' points='5.44,2.52 -2.52,5.44 -5.44,-2.52 2.52,-5.44 '>
                <animateTransform id='rise38' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.547389255773s' begin='0s' from='0 850' to='56 136' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.67768869812s' begin='0.647389255773s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.67768869812s' begin='0.547389255773s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.13831372746s' begin='0.547389255773s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='56 136' to='56 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.18570298324s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(331, 1350)' points='2.88,-0.84 0.84,2.88 -2.88,0.84 -0.84,-2.88 '>
                <animateTransform id='rise39' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.704424167159s' begin='0s' from='1440 850' to='331 132' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.267685713139s' begin='0.804424167159s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.267685713139s' begin='0.704424167159s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.54384623324s' begin='0.704424167159s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='331 132' to='331 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.7482704004s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 2 0; 0 0' begin='0.73797363024s' dur='1.59114266052s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(188, 1350)' points='4.82,-1.31 1.31,4.82 -4.82,1.31 -1.31,-4.82 '>
                <animateTransform id='rise40' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.73797363024s' begin='0s' from='0 850' to='188 257' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.774039130804s' begin='0.83797363024s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.774039130804s' begin='0.73797363024s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.19652599521s' begin='0.73797363024s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='188 257' to='188 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.43449962545s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(946, 1350)' points='3.86,-1.05 1.05,3.86 -3.86,1.05 -1.05,-3.86 '>
                <animateTransform id='rise41' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.709439715349s' begin='0s' from='1440 850' to='946 103' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.703381957372s' begin='0.809439715349s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.703381957372s' begin='0.709439715349s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.6463613833s' begin='0.709439715349s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='946 103' to='946 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.85580109865s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 21 0; 0 0' begin='0.702181452523s' dur='1.82556136989s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(708, 1350)' points='-5.0,-0.04 2.54,-4.31 2.46,4.35 -5.0,-0.04 '>
                <animateTransform id='rise43' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.655491147511s' begin='0s' from='1440 850' to='708 145' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.20499199372s' begin='0.755491147511s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.20499199372s' begin='0.655491147511s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.30654200112s' begin='0.655491147511s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='708 145' to='708 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.46203314863s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 20 0; 0 0' begin='0.702510290258s' dur='1.04185580451s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(774, 1350)' points='-5.98,-5.31 5.31,-5.98 5.98,5.31 -5.31,5.98 '>
                <animateTransform id='rise44' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.702510290258s' begin='0s' from='0 850' to='774 283' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.30912894743s' begin='0.802510290258s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.30912894743s' begin='0.702510290258s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.61182818566s' begin='0.702510290258s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='774 283' to='774 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.81433847592s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1383, 1350)' points='4.0,0.07 -0.07,4.0 -4.0,-0.07 0.07,-4.0 '>
                <animateTransform id='rise45' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.733276472756s' begin='0s' from='1440 850' to='1383 256' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.60187127707s' begin='0.833276472756s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.60187127707s' begin='0.733276472756s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.49046126478s' begin='0.733276472756s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1383 256' to='1383 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.72373773754s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 15 0; 0 0' begin='0.57677917987s' dur='1.94694303148s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(794, 1350)' points='-1.1,-3.85 3.85,-1.1 1.1,3.85 -3.85,1.1 '>
                <animateTransform id='rise46' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.57677917987s' begin='0s' from='0 850' to='794 56' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.498800647088s' begin='0.67677917987s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.498800647088s' begin='0.57677917987s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.77612675822s' begin='0.57677917987s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='794 56' to='794 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.85290593809s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(976, 1350)' points='-4.95,0.71 -0.71,-4.95 4.95,-0.71 0.71,4.95 '>
                <animateTransform id='rise47' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.786583156164s' begin='0s' from='1440 850' to='976 8' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.571192180534s' begin='0.886583156164s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.571192180534s' begin='0.786583156164s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.14516656123s' begin='0.786583156164s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='976 8' to='976 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.43174971739s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 15 0; 0 0' begin='0.724082474581s' dur='1.88870519544s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(1303, 1350)' points='-7.23,-3.43 3.43,-7.23 7.23,3.43 -3.43,7.23 '>
                <animateTransform id='rise48' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.724082474581s' begin='0s' from='0 850' to='1303 190' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.345626420215s' begin='0.824082474581s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.345626420215s' begin='0.724082474581s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.36623875199s' begin='0.724082474581s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1303 190' to='1303 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.59032122657s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(522, 1350)' points='5.49,-2.42 2.42,5.49 -5.49,2.42 -2.42,-5.49 '>
                <animateTransform id='rise49' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.742193110339s' begin='0s' from='1440 850' to='522 172' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.4561092532s' begin='0.842193110339s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.4561092532s' begin='0.742193110339s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.68992746182s' begin='0.742193110339s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='522 172' to='522 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.93212057216s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 32 0; 0 0' begin='0.593268092913s' dur='1.20090176092s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1170, 1350)' points='-3.92,-4.54 5.89,-1.13 -1.97,5.67 -3.92,-4.54 '>
                <animateTransform id='rise51' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.518826147626s' begin='0s' from='1440 850' to='1170 230' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.59818211373s' begin='0.618826147626s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.59818211373s' begin='0.518826147626s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.20927594718s' begin='0.518826147626s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1170 230' to='1170 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.22810209481s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 22 0; 0 0' begin='0.723570771588s' dur='1.26057962154s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(241, 1350)' points='-0.09,7.0 -7.0,-0.09 0.09,-7.0 7.0,0.09 '>
                <animateTransform id='rise52' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.723570771588s' begin='0s' from='0 850' to='241 208' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.83860078153s' begin='0.823570771588s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.83860078153s' begin='0.723570771588s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.34409194514s' begin='0.723570771588s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='241 208' to='241 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.56766271673s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(203, 1350)' points='-7.29,3.3 -3.3,-7.29 7.29,-3.3 3.3,7.29 '>
                <animateTransform id='rise53' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.706839088112s' begin='0s' from='1440 850' to='203 204' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.83071628882s' begin='0.806839088112s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.83071628882s' begin='0.706839088112s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.08566391744s' begin='0.706839088112s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='203 204' to='203 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.29250300555s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 5 0; 0 0' begin='0.658944467758s' dur='1.36168397113s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(975, 1350)' points='-7.23,-3.43 3.43,-7.23 7.23,3.43 -3.43,7.23 '>
                <animateTransform id='rise54' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.658944467758s' begin='0s' from='0 850' to='975 191' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.13771033878s' begin='0.758944467758s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.13771033878s' begin='0.658944467758s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.69328767331s' begin='0.658944467758s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='975 191' to='975 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.85223214107s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1429, 1350)' points='7.93,-1.06 1.06,7.93 -7.93,1.06 -1.06,-7.93 '>
                <animateTransform id='rise55' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.545117916715s' begin='0s' from='1440 850' to='1429 122' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.99510373018s' begin='0.645117916715s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.99510373018s' begin='0.545117916715s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.47752623676s' begin='0.545117916715s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1429 122' to='1429 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.52264415348s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 30 0; 0 0' begin='0.570550209048s' dur='1.06268735317s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1291, 1350)' points='-7.92,1.13 -1.13,-7.92 7.92,-1.13 1.13,7.92 '>
                <animateTransform id='rise56' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.570550209048s' begin='0s' from='0 850' to='1291 163' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.597924093233s' begin='0.670550209048s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.597924093233s' begin='0.570550209048s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.59243254131s' begin='0.570550209048s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1291 163' to='1291 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.66298275035s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 16 0; 0 0' begin='0.683653879992s' dur='1.43035083311s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1054, 1350)' points='5.93,0.9 -3.74,4.69 -2.19,-5.59 5.93,0.9 '>
                <animateTransform id='rise58' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.683653879992s' begin='0s' from='0 850' to='1054 115' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.313915464937s' begin='0.783653879992s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.313915464937s' begin='0.683653879992s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.29956942661s' begin='0.683653879992s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1054 115' to='1054 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.4832233066s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1176, 1350)' points='6.4,-2.83 2.83,6.4 -6.4,2.83 -2.83,-6.4 '>
                <animateTransform id='rise59' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.710553244302s' begin='0s' from='1440 850' to='1176 38' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.26539673892s' begin='0.810553244302s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.26539673892s' begin='0.710553244302s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.42387692362s' begin='0.710553244302s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1176 38' to='1176 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.63443016793s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 15 0; 0 0' begin='0.729810638401s' dur='1.31847790295s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(707, 1350)' points='1.08,-6.92 6.92,1.08 -1.08,6.92 -6.92,-1.08 '>
                <animateTransform id='rise60' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.729810638401s' begin='0s' from='0 850' to='707 9' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.48748435491s' begin='0.829810638401s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.48748435491s' begin='0.729810638401s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.48328599935s' begin='0.729810638401s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='707 9' to='707 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.71309663775s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1024, 1350)' points='-4.49,-3.98 3.98,-4.49 4.49,3.98 -3.98,4.49 '>
                <animateTransform id='rise61' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.595859261718s' begin='0s' from='1440 850' to='1024 139' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.381185524789s' begin='0.695859261718s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.381185524789s' begin='0.595859261718s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.3796434966s' begin='0.595859261718s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1024 139' to='1024 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.47550275832s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 19 0; 0 0' begin='0.793180444827s' dur='1.96784613765s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(722, 1350)' points='1.22,2.74 -2.74,1.22 -1.22,-2.74 2.74,-1.22 '>
                <animateTransform id='rise62' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.793180444827s' begin='0s' from='0 850' to='722 -2' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.449503417336s' begin='0.893180444827s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.449503417336s' begin='0.793180444827s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.27468347177s' begin='0.793180444827s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='722 -2' to='722 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.5678639166s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1391, 1350)' points='6.4,-2.83 2.83,6.4 -6.4,2.83 -2.83,-6.4 '>
                <animateTransform id='rise63' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.784161511328s' begin='0s' from='1440 850' to='1391 57' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.81677869015s' begin='0.884161511328s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.81677869015s' begin='0.784161511328s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.54146107419s' begin='0.784161511328s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1391 57' to='1391 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.82562258552s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 7 0; 0 0' begin='0.506995782861s' dur='1.70725340442s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(85, 1350)' points='5.06,-3.22 3.22,5.06 -5.06,3.22 -3.22,-5.06 '>
                <animateTransform id='rise64' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.506995782861s' begin='0s' from='0 850' to='85 225' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.816969463399s' begin='0.606995782861s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.816969463399s' begin='0.506995782861s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.12666890776s' begin='0.506995782861s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='85 225' to='85 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.13366469062s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(1056, 1350)' points='-1.6,-3.67 3.67,-1.6 1.6,3.67 -3.67,1.6 '>
                <animateTransform id='rise65' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.671023798333s' begin='0s' from='1440 850' to='1056 234' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.34100197222s' begin='0.771023798333s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.34100197222s' begin='0.671023798333s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.81922037121s' begin='0.671023798333s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1056 234' to='1056 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.99024416955s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 11 0; 0 0' begin='0.653108210322s' dur='1.05372379393s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1339, 1350)' points='5.0,0.09 -0.09,5.0 -5.0,-0.09 0.09,-5.0 '>
                <animateTransform id='rise66' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.653108210322s' begin='0s' from='0 850' to='1339 189' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.75629388391s' begin='0.753108210322s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.75629388391s' begin='0.653108210322s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.46364992935s' begin='0.653108210322s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1339 189' to='1339 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.61675813967s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(714, 1350)' points='2.59,3.05 -3.05,2.59 -2.59,-3.05 3.05,-2.59 '>
                <animateTransform id='rise67' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.603850753107s' begin='0s' from='1440 850' to='714 219' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.852236009245s' begin='0.703850753107s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.852236009245s' begin='0.603850753107s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.63935184839s' begin='0.603850753107s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='714 219' to='714 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.7432026015s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 9 0; 0 0' begin='0.516647310159s' dur='1.58751200942s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(578, 1350)' points='-2.24,-1.99 1.99,-2.24 2.24,1.99 -1.99,2.24 '>
                <animateTransform id='rise68' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.516647310159s' begin='0s' from='0 850' to='578 163' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.708640844271s' begin='0.616647310159s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.708640844271s' begin='0.516647310159s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.76025077423s' begin='0.516647310159s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='578 163' to='578 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.77689808439s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1133, 1350)' points='2.26,1.97 -1.97,2.26 -2.26,-1.97 1.97,-2.26 '>
                <animateTransform id='rise69' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.53625571016s' begin='0s' from='1440 850' to='1133 31' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.82020404994s' begin='0.63625571016s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.82020404994s' begin='0.53625571016s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.23956746059s' begin='0.53625571016s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1133 31' to='1133 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.27582317075s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 8 0; 0 0' begin='0.762539682703s' dur='1.92066846402s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(350, 1350)' points='2.45,5.48 -5.48,2.45 -2.45,-5.48 5.48,-2.45 '>
                <animateTransform id='rise70' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.762539682703s' begin='0s' from='0 850' to='350 248' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.608725742861s' begin='0.862539682703s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.608725742861s' begin='0.762539682703s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.68610142961s' begin='0.762539682703s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='350 248' to='350 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.94864111232s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(431, 1350)' points='-1.07,-1.69 1.69,-1.07 1.07,1.69 -1.69,1.07 '>
                <animateTransform id='rise71' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.520123399262s' begin='0s' from='1440 850' to='431 54' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.512603648223s' begin='0.620123399262s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.512603648223s' begin='0.520123399262s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.05602248576s' begin='0.520123399262s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='431 54' to='431 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.07614588502s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 18 0; 0 0' begin='0.752489683923s' dur='1.86807327287s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(801, 1350)' points='-1.73,3.61 -3.61,-1.73 1.73,-3.61 3.61,1.73 '>
                <animateTransform id='rise72' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.752489683923s' begin='0s' from='0 850' to='801 180' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.875061593439s' begin='0.852489683923s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.875061593439s' begin='0.752489683923s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.91132665472s' begin='0.752489683923s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='801 180' to='801 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.16381633864s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <circle fill='#F56681' strokeWidth='0.7' transform='translate(1428, 1350)' r='2'>
                <animateTransform id='rise73' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.52997286848s' begin='0s' from='1440 850' to='1428 -3' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.304764700556s' begin='0.62997286848s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.304764700556s' begin='0.52997286848s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.19770650769s' begin='0.52997286848s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1428 -3' to='1428 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='3.22767937617s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 14 0; 0 0' begin='0.635135717248s' dur='1.47148394453s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1218, 1350)' points='7.0,0.0 0.0,7.0 -7.0,0.0 -0.0,-7.0 '>
                <animateTransform id='rise74' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.635135717248s' begin='0s' from='0 850' to='1218 139' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.68080335463s' begin='0.735135717248s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.68080335463s' begin='0.635135717248s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.04304112486s' begin='0.635135717248s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1218 139' to='1218 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.17817684211s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1227, 1350)' points='-3.46,7.21 -7.21,-3.46 3.46,-7.21 7.21,3.46 '>
                <animateTransform id='rise75' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.662854873994s' begin='0s' from='1440 850' to='1227 -5' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.70300829766s' begin='0.762854873994s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.70300829766s' begin='0.662854873994s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.53696552276s' begin='0.662854873994s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1227 -5' to='1227 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.69982039675s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 6 0; 0 0' begin='0.571170940139s' dur='1.93170868799s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1120, 1350)' points='4.0,0.0 0.0,4.0 -4.0,0.0 -0.0,-4.0 '>
                <animateTransform id='rise76' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.571170940139s' begin='0s' from='0 850' to='1120 148' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.843907046513s' begin='0.671170940139s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.843907046513s' begin='0.571170940139s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.48329803619s' begin='0.571170940139s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1120 148' to='1120 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.55446897633s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 29 0; 0 0' begin='0.763439234498s' dur='1.38189841131s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(737, 1350)' points='1.93,-0.52 -0.51,1.93 -1.42,-1.41 1.93,-0.52 '>
                <animateTransform id='rise78' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.763439234498s' begin='0s' from='0 850' to='737 125' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.05644040938s' begin='0.863439234498s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.05644040938s' begin='0.763439234498s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.593960259s' begin='0.763439234498s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='737 125' to='737 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.85739949349s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(1121, 1350)' points='-7.94,0.99 -0.99,-7.94 7.94,-0.99 0.99,7.94 '>
                <animateTransform id='rise79' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.725687820672s' begin='0s' from='1440 850' to='1121 66' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.94715563418s' begin='0.825687820672s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.94715563418s' begin='0.725687820672s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.98674681587s' begin='0.725687820672s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1121 66' to='1121 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.21243463654s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 23 0; 0 0' begin='0.770486158759s' dur='1.16340062233s' repeatCount='indefinite' />
            <circle fill='#61CDFF' strokeWidth='0.7' transform='translate(534, 1350)' r='8'>
                <animateTransform id='rise80' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.770486158759s' begin='0s' from='0 850' to='534 208' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.96960839307s' begin='0.870486158759s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.96960839307s' begin='0.770486158759s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.78721248712s' begin='0.770486158759s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='534 208' to='534 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='3.05769864588s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1254, 1350)' points='1.58,2.55 -2.55,1.58 -1.58,-2.55 2.55,-1.58 '>
                <animateTransform id='rise81' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.778861929716s' begin='0s' from='1440 850' to='1254 29' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.14406008398s' begin='0.878861929716s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.14406008398s' begin='0.778861929716s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.42201917435s' begin='0.778861929716s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1254 29' to='1254 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.70088110407s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 18 0; 0 0' begin='0.655831926541s' dur='1.67118658771s' repeatCount='indefinite' />
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 12 0; 0 0' begin='0.518421727236s' dur='1.26594265758s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(941, 1350)' points='3.95,0.6 -2.5,3.13 -1.46,-3.72 3.95,0.6 '>
                <animateTransform id='rise84' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.518421727236s' begin='0s' from='0 850' to='941 129' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.27333050307s' begin='0.618421727236s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.27333050307s' begin='0.518421727236s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.64884174977s' begin='0.518421727236s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='941 129' to='941 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.66726347701s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(348, 1350)' points='1.22,2.74 -2.74,1.22 -1.22,-2.74 2.74,-1.22 '>
                <animateTransform id='rise85' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.76451707342s' begin='0s' from='1440 850' to='348 84' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.276766711015s' begin='0.86451707342s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.276766711015s' begin='0.76451707342s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.32913491204s' begin='0.76451707342s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='348 84' to='348 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.59365198546s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 7 0; 0 0' begin='0.551866796122s' dur='1.31886797584s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(578, 1350)' points='-7.92,1.13 2.98,-7.42 4.94,6.29 -7.92,1.13 '>
                <animateTransform id='rise87' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.504362934228s' begin='0s' from='1440 850' to='578 43' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.36294258244s' begin='0.604362934228s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.36294258244s' begin='0.504362934228s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.05907758108s' begin='0.504362934228s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='578 43' to='578 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.06344051531s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 8 0; 0 0' begin='0.7339494616s' dur='1.73655804085s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(236, 1350)' points='-6.08,5.2 -5.2,-6.08 6.08,-5.2 5.2,6.08 '>
                <animateTransform id='rise88' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.7339494616s' begin='0s' from='0 850' to='236 165' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.52684218826s' begin='0.8339494616s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.52684218826s' begin='0.7339494616s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.35303917243s' begin='0.7339494616s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='236 165' to='236 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.58698863403s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(865, 1350)' points='1.09,7.92 -7.92,1.09 -1.09,-7.92 7.92,-1.09 '>
                <animateTransform id='rise89' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.69909556165s' begin='0s' from='1440 850' to='865 192' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.802353887904s' begin='0.79909556165s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.802353887904s' begin='0.69909556165s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.43735713515s' begin='0.69909556165s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='865 192' to='865 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.6364526968s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 16 0; 0 0' begin='0.729349406073s' dur='1.31336591912s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(388, 1350)' points='-2.73,1.24 -1.24,-2.73 2.73,-1.24 1.24,2.73 '>
                <animateTransform id='rise90' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.729349406073s' begin='0s' from='0 850' to='388 216' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.758804373816s' begin='0.829349406073s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.758804373816s' begin='0.729349406073s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.34573904766s' begin='0.729349406073s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='388 216' to='388 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.57508845373s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1366, 1350)' points='-2.19,3.35 -3.35,-2.19 2.19,-3.35 3.35,2.19 '>
                <animateTransform id='rise91' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.678447167345s' begin='0s' from='1440 850' to='1366 266' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.05775115163s' begin='0.778447167345s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.05775115163s' begin='0.678447167345s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.79152936129s' begin='0.678447167345s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1366 266' to='1366 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.96997652863s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 3 0; 0 0' begin='0.557919018171s' dur='1.46778631102s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(136, 1350)' points='-1.1,-3.85 3.88,0.97 -2.78,2.88 -1.1,-3.85 '>
                <animateTransform id='rise93' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.741655169466s' begin='0s' from='1440 850' to='136 129' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.49821961843s' begin='0.841655169466s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.49821961843s' begin='0.741655169466s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.21821163868s' begin='0.741655169466s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='136 129' to='136 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.45986680814s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 29 0; 0 0' begin='0.518543673047s' dur='1.88574280337s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(42, 1350)' points='-6.79,4.23 -4.23,-6.79 6.79,-4.23 4.23,6.79 '>
                <animateTransform id='rise94' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.518543673047s' begin='0s' from='0 850' to='42 37' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.63370897382s' begin='0.618543673047s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.63370897382s' begin='0.518543673047s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.29785422709s' begin='0.518543673047s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='42 37' to='42 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.31639790014s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(530, 1350)' points='-3.2,-5.08 5.08,-3.2 3.2,5.08 -5.08,3.2 '>
                <animateTransform id='rise95' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.665304869152s' begin='0s' from='1440 850' to='530 -20' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.892443897639s' begin='0.765304869152s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.892443897639s' begin='0.665304869152s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.8588092774s' begin='0.665304869152s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='530 -20' to='530 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.02411414656s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 24 0; 0 0' begin='0.710808319805s' dur='1.84682205737s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(300, 1350)' points='0.04,-8.0 8.0,0.04 -0.04,8.0 -8.0,-0.04 '>
                <animateTransform id='rise96' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.710808319805s' begin='0s' from='0 850' to='300 153' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.39464935546s' begin='0.810808319805s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.39464935546s' begin='0.710808319805s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.41325294692s' begin='0.710808319805s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='300 153' to='300 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.62406126672s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 11 0; 0 0' begin='0.648101602266s' dur='1.60785462722s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1274, 1350)' points='-1.66,3.64 -2.32,-3.26 3.98,-0.38 -1.66,3.64 '>
                <animateTransform id='rise98' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.648101602266s' begin='0s' from='0 850' to='1274 84' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.80823419295s' begin='0.748101602266s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.80823419295s' begin='0.648101602266s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.29760200839s' begin='0.648101602266s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1274 84' to='1274 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.44570361066s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(534, 1350)' points='-1.02,-7.93 7.93,-1.02 1.02,7.93 -7.93,1.02 '>
                <animateTransform id='rise99' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.633893399882s' begin='0s' from='1440 850' to='534 243' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.71686381972s' begin='0.733893399882s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.71686381972s' begin='0.633893399882s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.59835943484s' begin='0.633893399882s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='534 243' to='534 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.73225283472s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 30 0; 0 0' begin='0.764422039829s' dur='1.73344652893s' repeatCount='indefinite' />
            <polygon fill='#FFB853' strokeWidth='0.7' transform='translate(55, 1350)' points='-6.95,0.87 -0.87,-6.95 6.95,-0.87 0.87,6.95 '>
                <animateTransform id='rise100' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.764422039829s' begin='0s' from='0 850' to='55 78' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.01286336293s' begin='0.864422039829s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.01286336293s' begin='0.764422039829s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFED90; #FFB853; #FFED90; #FF8B00; #FFED90' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.25931977362s' begin='0.764422039829s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='55 78' to='55 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.52374181345s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <circle fill='#6680F5' strokeWidth='0.7' transform='translate(1372, 1350)' r='3'>
                <animateTransform id='rise101' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.578177759335s' begin='0s' from='1440 850' to='1372 177' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.667229105s' begin='0.678177759335s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.667229105s' begin='0.578177759335s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.20496393375s' begin='0.578177759335s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1372 177' to='1372 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='0.5s' begin='2.28314169308s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </circle>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 7 0; 0 0' begin='0.558031636969s' dur='1.59475562594s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(911, 1350)' points='2.55,-5.43 5.43,2.55 -2.55,5.43 -5.43,-2.55 '>
                <animateTransform id='rise102' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.558031636969s' begin='0s' from='0 850' to='911 48' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.782161758113s' begin='0.658031636969s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.782161758113s' begin='0.558031636969s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.2283626504s' begin='0.558031636969s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='911 48' to='911 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.28639428737s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1030, 1350)' points='5.0,0.09 -0.09,5.0 -5.0,-0.09 0.09,-5.0 '>
                <animateTransform id='rise103' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.732359381446s' begin='0s' from='1440 850' to='1030 113' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.00774479595s' begin='0.832359381446s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.00774479595s' begin='0.732359381446s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.09147625257s' begin='0.732359381446s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1030 113' to='1030 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.32383563401s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 23 0; 0 0' begin='0.737490259068s' dur='1.46536009652s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(820, 1350)' points='6.69,2.07 -2.07,6.69 -6.69,-2.07 2.07,-6.69 '>
                <animateTransform id='rise104' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.737490259068s' begin='0s' from='0 850' to='820 152' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.41469750343s' begin='0.837490259068s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.41469750343s' begin='0.737490259068s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.51987995808s' begin='0.737490259068s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='820 152' to='820 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.75737021715s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 27 0; 0 0' begin='0.75136818266s' dur='1.95262271368s' repeatCount='indefinite' />
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1072, 1350)' points='0.27,1.98 -1.85,-0.75 1.58,-1.23 0.27,1.98 '>
                <animateTransform id='rise106' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.75136818266s' begin='0s' from='0 850' to='1072 227' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.76244433585s' begin='0.85136818266s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.76244433585s' begin='0.75136818266s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.69432476949s' begin='0.75136818266s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1072 227' to='1072 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.94569295215s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(158, 1350)' points='1.09,7.92 -7.92,1.09 -1.09,-7.92 7.92,-1.09 '>
                <animateTransform id='rise107' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.695136447518s' begin='0s' from='1440 850' to='158 109' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.53613241622s' begin='0.795136447518s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.53613241622s' begin='0.695136447518s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.2817633827s' begin='0.695136447518s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='158 109' to='158 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.47689983022s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 30 0; 0 0' begin='0.667807662358s' dur='1.38314728261s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(523, 1350)' points='1.13,-3.84 3.84,1.13 -1.13,3.84 -3.84,-1.13 '>
                <animateTransform id='rise108' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.667807662358s' begin='0s' from='0 850' to='523 274' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.291703973156s' begin='0.767807662358s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.291703973156s' begin='0.667807662358s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.94405624892s' begin='0.667807662358s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='523 274' to='523 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.11186391128s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(1298, 1350)' points='-0.26,-1.98 1.98,-0.26 0.26,1.98 -1.98,0.26 '>
                <animateTransform id='rise109' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.70497203611s' begin='0s' from='1440 850' to='1298 96' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.92968167006s' begin='0.80497203611s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.92968167006s' begin='0.70497203611s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.91725501028s' begin='0.70497203611s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1298 96' to='1298 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.12222704639s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 7 0; 0 0' begin='0.514899271995s' dur='1.51955738852s' repeatCount='indefinite' />
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(234, 1350)' points='-2.13,-3.38 3.38,-2.13 2.13,3.38 -3.38,2.13 '>
                <animateTransform id='rise110' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.514899271995s' begin='0s' from='0 850' to='234 70' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.60617791072s' begin='0.614899271995s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.60617791072s' begin='0.514899271995s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.09528379058s' begin='0.514899271995s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='234 70' to='234 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.11018306257s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(1378, 1350)' points='-7.29,3.3 -3.3,-7.29 7.29,-3.3 3.3,7.29 '>
                <animateTransform id='rise111' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.555995471926s' begin='0s' from='1440 850' to='1378 99' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.458455312586s' begin='0.655995471926s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.458455312586s' begin='0.555995471926s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.51951882127s' begin='0.555995471926s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1378 99' to='1378 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.5755142932s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 24 0; 0 0' begin='0.54175917835s' dur='1.76332059023s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(87, 1350)' points='-4.56,3.9 -3.9,-4.56 4.56,-3.9 3.9,4.56 '>
                <animateTransform id='rise112' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.54175917835s' begin='0s' from='0 850' to='87 -14' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.92006348279s' begin='0.64175917835s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.92006348279s' begin='0.54175917835s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.6013885628s' begin='0.54175917835s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='87 -14' to='87 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.64314774115s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#6680F5' strokeWidth='0.7' transform='translate(743, 1350)' points='2.72,1.26 -1.26,2.72 -2.72,-1.26 1.26,-2.72 '>
                <animateTransform id='rise113' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.690951770098s' begin='0s' from='1440 850' to='743 4' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.420213054929s' begin='0.790951770098s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.420213054929s' begin='0.690951770098s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#E8DFFF; #6680F5; #E8DFFF; #3B1797; #E8DFFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='2.750778115s' begin='0.690951770098s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='743 4' to='743 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='2.94172988509s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 26 0; 0 0' begin='0.615528160577s' dur='1.4243222283s' repeatCount='indefinite' />
        </g>
        <g>
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(860, 1350)' points='-2.0,-4.58 4.97,0.56 -2.97,4.02 -2.0,-4.58 '>
                <animateTransform id='rise115' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.796525418532s' begin='0s' from='1440 850' to='860 33' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.35212886271s' begin='0.896525418532s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.35212886271s' begin='0.796525418532s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.3406469604s' begin='0.796525418532s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='860 33' to='860 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.63717237893s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 6 0; 0 0' begin='0.700899648678s' dur='1.33872755486s' repeatCount='indefinite' />
            <polygon fill='#61CDFF' strokeWidth='0.7' transform='translate(710, 1350)' points='2.12,-4.53 4.53,2.12 -2.12,4.53 -4.53,-2.12 '>
                <animateTransform id='rise116' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.700899648678s' begin='0s' from='0 850' to='710 52' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.32006713354s' begin='0.800899648678s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.32006713354s' begin='0.700899648678s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#55EDFF; #61CDFF; #55EDFF; #177F8C; #55EDFF' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.24916627738s' begin='0.700899648678s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='710 52' to='710 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.45006592606s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#1AC8A0' strokeWidth='0.7' transform='translate(517, 1350)' points='-5.42,-2.57 2.57,-5.42 5.42,2.57 -2.57,5.42 '>
                <animateTransform id='rise117' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.790399531384s' begin='0s' from='1440 850' to='517 253' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.8388389428s' begin='0.890399531384s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.8388389428s' begin='0.790399531384s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#5CFF73; #1AC8A0; #5CFF73; #0D5B18; #5CFF73' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.08792308377s' begin='0.790399531384s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='517 253' to='517 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.37832261515s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <animateMotion calcMode='spline' keyTimes='0; 0.5; 1' keySplines='0.5 0 0.5 1; 0.5 0 0.5 1' values='0 0; 18 0; 0 0' begin='0.706311259123s' dur='1.3082082962s' repeatCount='indefinite' />
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(1118, 1350)' points='5.0,0.0 0.0,5.0 -5.0,0.0 -0.0,-5.0 '>
                <animateTransform id='rise118' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.706311259123s' begin='0s' from='0 850' to='1118 125' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='1.03013820204s' begin='0.806311259123s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='1.03013820204s' begin='0.706311259123s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.31930230168s' begin='0.706311259123s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='1118 125' to='1118 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.5256135608s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
        <g>
            <polygon fill='#F56681' strokeWidth='0.7' transform='translate(484, 1350)' points='-3.46,7.21 -7.21,-3.46 3.46,-7.21 7.21,3.46 '>
                <animateTransform id='rise119' calcMode='spline' keyTimes='0; 1' keySplines='0 0.5 0.2 1' dur='0.657703758136s' begin='0s' from='1440 850' to='484 142' attributeName='transform' type='translate' repeatCount='1' />
                <animateTransform dur='0.711485423659s' begin='0.757703758136s' keyTimes='0; 0.5; 1' values='1 -1; 1 1; 1 -1' attributeName='transform' type='scale' repeatCount='indefinite' additive='sum' />
                <animate dur='0.711485423659s' begin='0.657703758136s' keyTimes='0; 0.25; 0.5; 0.75; 1' values='#FFB7B7; #F56681; #FFB7B7; #FF0030; #FFB7B7' attributeName='fill' repeatCount='indefinite' />
                <animateTransform dur='3.53126649918s' begin='0.657703758136s' calcMode='spline' keyTimes='0;1' keySplines='0.3 0 0.5 0.5' from='484 142' to='484 900' attributeName='transform' type='translate' repeatCount='1' />
                <animate dur='.5s' begin='3.68897025732s' from='1' to='0' attributeName='opacity' repeatCount='1' />
            </polygon>
        </g>
    </svg>
    )
}

export default Confettisvg;