import '../../Ui.css'

import linkedin from '../../assets/homepage/linkedinicon.png'
import codepen from '../../assets/homepage/codepenicon.png'
import notion from '../../assets/homepage/notionicon.png'
import replit from '../../assets/homepage/repliticon.png'

const Logoicon = (props) =>{
    return (
        <div style={{display:'flex', ...props.style}}>
            <a className='logoicon' href='https://www.linkedin.com/in/menghui-hu-773566130/' target='_blank'>
                <img style={{width:'16px'}} src={linkedin}></img>
            </a>
            <a className='logoicon' href='https://codepen.io/menghuihu' target='_blank'>
                <img style={{width:'16px'}} src={codepen}></img>
            </a>
            <a className='logoicon' href='https://www.notion.so/menghuih/Autodesk-projects-3456a49aded04106ab7360c0523a08bd' target='_blank'>
                <img style={{width:'16px'}} src={notion}></img>
            </a>
            <a className='logoicon' style={{margin:'0px'}} href='https://replit.com/@MenghuiHu' target='_blank'>
                <img style={{width:'20px'}} src={replit}></img>
            </a>
        </div>
    )
}

export default Logoicon;