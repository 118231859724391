import './App.css';
import Header from './components/Header'
import Homepage from './pages/Homepage'
import {Routes, Route, useParams} from 'react-router-dom'
import Footer from './components/Footer'
import Minutes from './pages/Minutes'
import Darkmode from './pages/Darkmode'
import Plugin from './pages/Plugin'
import Bubble from './pages/Bubble'
import Ibi from './pages/Ibi'
import ScrollToTop from './components/ui/ScrollToTop'
import Confetti from './pages/Confetti'
import Load from './pages/Load'
import Photoselect from './pages/Photoselect'
import Fab from './pages/Fab'
import Autodesk from './pages/Autodesk'
import Microsoft from './pages/Microsoft'

function App() {
  return (
    <div className="App">
      <Header />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="microsoft" element={<Microsoft />} />
            <Route path="minutes" element={<Minutes />} />
            <Route path='darkmode' element={<Darkmode />} />
            <Route path='plugin' element={<Plugin />} />
            <Route path='bubble' element={<Bubble />} />
            <Route path='autodesk' element={<Autodesk />} />

            <Route path='ibi' element={<Ibi />} />
            <Route path='confetti' element={<Confetti />} />
            <Route path='load' element={<Load />} />
            <Route path='select' element={<Photoselect />} />
            <Route path='fab' element={<Fab />} />          
          </Routes>
        </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
