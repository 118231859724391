const InlineLink = (props) => {
    return (
        <a className={`ui-link ${props.className}`} href={props.href} target='_blank'>
            <div className='ui-link-text'>
                {props.children}
            </div>
            <div className='ui-link-underline'></div>
        </a>
    )
}

export default InlineLink;