import '../../Ui.css'

const Button = (props) => {
    return (
        <a className={`ui-button ${props.className}`} href={props.href} target='_blank'>
            <div>{props.children}</div>
        </a>
    )
}

export default Button;