import '../App.css'
import Link from '../components/ui/Link'
import ProjectsHeader from '../components/ProjectsHeader'
import bytedanceLogo from '../assets/homepage/bytedancelogo.png'
// import prototypeTile from '../assets/homepage/prototype.png'
import profile from '../assets/homepage/profile.png'
import autodesklogo from '../assets/homepage/autodesklogo.png'
import sandisklogo from '../assets/homepage/sandisklogo.png'
import minutesTile from '../assets/homepage/minutes-tile.png'
import darkmodeTile from '../assets/homepage/darkmode-tile.png'
import autodeskTile from '../assets/homepage/autodesk-tile.png'
import ibiTile from '../assets/homepage/ibi-tile.png'

import prototypePlugin from '../assets/homepage/prototype-plugin.png'
import prototypeBubble from '../assets/homepage/prototype-bubble.png'
import prototypeConfetti from '../assets/homepage/prototype-confetti.png'
import prototypeLoad from '../assets/homepage/prototype-load.png'
import prototypeSelect from '../assets/homepage/prototype-select.png'
import prototypeButton from '../assets/homepage/prototype-button.png'

import CanvasBubble from '../components/ui/CanvasBubble'
import Project from '../components/Project'
import Article from '../components/Article'
import Prototype from '../components/Prototype'
import Logoicon from '../components/ui/Logoicon'
import InlineLink from '../components/ui/InlineLink'

const Homepage = () =>{
    return (
        <div className='homepage'>
            <div className='homepage-about'>
                <img style={{width:'64px'}} src={profile}></img>
                <div className='homepage-about-text h1 m-top-24'>I’m Menghui 👋, design lead at Bytedance</div>
                <div className='homepage-about-text m-top-24 p2-home'>I am currently leading the design of video products at Bytedance.</div>
                <div className='homepage-about-text m-top-12 p2-home m-bottom-24'>In my spare time, I enjoy tinkering prototypes and developing internal tools to help improve the ways designers work.</div>
                <Logoicon />
                <CanvasBubble className='homepage-canvas'/>
            </div>
            <div id='projects'></div>
            <div className='homepage-projects'>
                <ProjectsHeader img={bytedanceLogo} title='Design lead'> - I lead the design of video products and part of design archetecture projects across the orgnization.</ProjectsHeader>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                    <Link to='/minutes'><Project img={minutesTile} title='Bytedance Minutes - Video record & transcription' caption='product design' tag={['Design lead', '2020 - present']}></Project></Link>
                    <Link to='/darkmode'><Project img={darkmodeTile} title='Design system - Dark mode for Lark suite' caption='product design' tag={['Design lead', '2021']}></Project></Link>
                    <Link to='/plugin'><Prototype className='m-top-24' img={prototypePlugin} title={`Theme switch`} caption='figma plugin' tag={['Javascript', '2021/10']}></Prototype></Link>
                    <Link to='/bubble'><Prototype className='m-top-24' img={prototypeBubble} title='Bubble' caption='prototype' tag={['Javascript', '2021/5']}></Prototype></Link>
                    <Article className='m-top-24' caption='publication' title='Lark 5.0 dark mode' href='https://mp.weixin.qq.com/s/mFbCZbF5bSkyEsHZ5ZUBnA' intro='In November 2021, Bytedance Lark introduced dark mode for the first time at the annul conference...' tag={['Editor', '2022/1']}></Article>
                </div>
                <ProjectsHeader img={autodesklogo} title='Senior experience designer'> - In charge of product design of Autodesk drive, co-created design process for the Autodesk Forge team.</ProjectsHeader>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                    <Link to='/autodesk'><Project img={autodeskTile} title='Autodesk Viewer - online 3D model viewer' caption='product design' tag={['Design lead','2019-2020']}></Project></Link>
                    <div style={{display:'flex', width:'528px', flexWrap:'wrap', justifyContent:'space-between'}}>
                        <Article href='https://menghuih.notion.site/Design-process-3510ac390f3e416b88bfc23caa0911f4' caption='Internal article' title='Design process' intro='At Autodesk, we take the "double dimond" design approach from research phase to solution phase...' tag={['Editor', '2020']}></Article>
                        <Article href='https://menghuih.notion.site/AI-Powered-Customer-Insights-for-Autodesk-Viewer-47fc2e3ab86c4517be34507b07f8ede2' className='m-top-24' caption='Internal article' title='AI powered customer insights' intro='To facilitate data comprehension, we created a live dashboard that would condense the data in a quick...' tag={['Editor', '2020']}></Article>
                    </div>
                </div>

                <ProjectsHeader img={sandisklogo} title='Product designer'> - designer on ibi, joined through startup acquisition. Built prototyes for early concepts and high fidelity interactions that went into code.</ProjectsHeader>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                    <Link to='/ibi'><Project img={ibiTile} title='ibi - smart photo manager' caption='product design' tag={['Product designer', '2018-2019']}></Project></Link>
                    <div style={{display:'flex', width:'528px', flexWrap:'wrap', justifyContent:'space-between'}}>
                        <Link to='/confetti'><Prototype img={prototypeConfetti} caption='prototype' title='Confetti' tag={['Python', '2017']}></Prototype></Link>
                        <Link to='/load'><Prototype img={prototypeLoad} caption='prototype' title='Load' tag={['Javascript', '2017']}></Prototype></Link>
                        <Link to='/select'><Prototype className='m-top-24' caption='prototype' img={prototypeSelect} title='Photo select' tag={['Javascript', '2018']}></Prototype></Link>
                        <Link to='/fab'><Prototype className='m-top-24' caption='prototype' img={prototypeButton} title='"Fab"' tag={['Framer JS', '2018']}></Prototype></Link>
                    </div>
                </div>
            </div>
            <div style={{height:'48px'}}></div>
            <div style={{height:'48px'}} id='homepage-bio'></div>
            <div className='homepage-bio'>
                <div className='homepage-bio-b p2'>
                    <div className='homepage-bio-p'>I'm Menghui, a Chinese living in San Francisco, California. Since 2016 I designed products with companies such as Autodesk, Bytedance and SurveyMonkey.<br /><br />At around 2012, I discovered my passion for interaction design when I was at my undergrad studying industrial design. Then I went to human computer interaction at Carnegie Mellon University in grad school.<br /><br />That eventually led me to my first job as a designer at SurveyMonkey, I designed onboarding experience with <InlineLink href='https://twitter.com/Luchi'>Lucia Ciccio</InlineLink> and core survey experience with <InlineLink href='http://www.supafrenz.com/index.html'>Jonathan Remulla</InlineLink>.</div>
                    <div className='homepage-bio-p'>In 2017, I joined <InlineLink href='https://www.linkedin.com/in/laurentbaumann/'>Laurent Baumann</InlineLink> and the rest of the incredibly talented folks at Uphtere. Uphtere was cloud storage startup, it was acquired by Western digital. After the acquisition we managed to integrate the cloud technology with hardware storage and introduced a new product line ibi for SanDisk. <br /><br />Coming 2019, I joined Autodesk as a senior designer. At Autodesk I led the design of Autodesk Drive with <InlineLink href='https://www.linkedin.com/in/alicechangdesign/'>Alice Chang</InlineLink> and <InlineLink href='https://www.linkedin.com/in/tobiashathorn/'>Tobias Hathorn</InlineLink>. This happened to be an exiciting period for Autodesk, as the company took its first step into the world of cloud and online collaboration. Autodesk Viewer, is one of the products shipped from my team.</div>
                    <div className='homepage-bio-p'>In 2020, when the global pandemic came, I decided to relocate back to China for a while, and joined Bytedance as a design lead on enterprise products orgnization. I am currently leading the design of innovation team for video products, and part of design archetecture projects across the orgnization. In my spare time I enjoy tinkering prototypes and internal tools to help improve the way designers work.</div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;