import '../../App.css'

const Caption = (props) => {
    return (
        <div style={{display:'inline-block'}} className={props.className}>
            <div className='caption1'>{props.children}
            </div>
            <div style={{height: '1px', width:'100%', backgroundColor:'#c4c4c4'}}></div>

        </div>
    )
}

export default Caption