import Tag from './ui/Tag'

const Prototype = (props) => {
    const tag = props.tag.map((tag)=>{
        return (
            <Tag key={Math.random()} className='m-bottom-8'>{tag}</Tag>
        )
    })

    return (
        <div className={`prototype-tile tile ${props.className}`}>
            <img style={{height: '220px', borderRadius: '32px'}} src={props.img}></img>
            <div style={{position: 'absolute', top:'40px', left:'40px', display:'flex', height:'180px',flexDirection:'column', justifyContent:'space-between'}}>
                <div>
                    <div className='caption1 m-bottom-12 text-grey'>{props.caption}</div>
                    <div className='h3 m-bottom-16 default-color' style={{width: '120px'}}>{props.title}</div>
                </div>
                <div style={{display: 'flex', alignItems:"flex-start", flexDirection: "column"}} className='default-color'>
                    {tag}
                </div>
            </div>
        </div>
    )
}

export default Prototype;