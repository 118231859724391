import selectVideo from '../assets/select/select.mp4'
import Button from '../components/ui/Button'

const Photoselect = () => {
    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>Bulk photo select interaction</div>
            <div className='p2 text-grey m-top-24 con-720 text-center'>Selecting photo is an essential step for ibi app. The interaction creates an intuitive way to select multiple photos, by simple drag all photos the user wants to select. The design was shipped on ibi app.</div>
            <Button className='m-top-24' href='https://floralwhiteachingoutliers.menghuihu.repl.co/'>View on Replit</Button>
            {/* <div className='m-top-48 con-1080' style={{ borderRadius: '48px', padding: '48px 0', backgroundColor: '#f8f8f8', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                <video width="1080" height="680" loop autoPlay muted className='m-top-48 r-48' style={{boxShadow:'0 0 0 1.5px rgba(239,239,239,1)'}}>
                    <source src={selectVideo} type="video/mp4" />
                </video>
            {/* </div> */}
        </div>
    )
}

export default Photoselect