import '../App.css'
import { HashLink as Link } from 'react-router-hash-link'

const Header = () => {
    return (
        <div className='header'>
            <div className='header-list'>
                <div>
                    <Link to='/' className='header-list-item default-color'>Home</Link>
                    <Link to={{ pathname: '/', hash: '#projects' }} className='header-list-item default-color'>Projects</Link>
                    <Link to={{ pathname: '/', hash: '#homepage-bio' }} className='header-list-item default-color'>About me</Link>
                </div>
                <div>
                    <div className='header-list-item' style={{marginRight:'8px', cursor:'not-allowed', color:'#999999', position:'relative', fontFamily:''}}>
                        中
                    </div>
                    <div className='header-list-item' style={{marginRight:'0',cursor:'pointer', position: 'relative'}}>EN
                    <div style={{position:'absolute', height:'2px', width:'20px', backgroundColor:'#323232', bottom:'4px', borderRadius:'4px'}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;