import '../App.css';
import research from '../assets/microsoft/research.png'
import research1 from '../assets/microsoft/research1.png'
import affinitymap from '../assets/microsoft/affinitymap.png'
import problem1 from '../assets/microsoft/problem.png'
import onboarding from '../assets/microsoft/onboarding.png'
import findspot from '../assets/microsoft/find.png'
import rentspot from '../assets/microsoft/rent.png'
import hifi1 from '../assets/microsoft/hifi1.png'
import hifi2 from '../assets/microsoft/hifi2.png'
import profile from '../assets/microsoft/profile.png'
import brainstorm from '../assets/microsoft/brainstorm.png'
import userstory from '../assets/microsoft/userstory.png'

const Microsoft = () => {
    return (
        <div>
            <div className='con-1080 m-auto h1 m-top-96'>
                <div className='con-720'>Car parking in China - Microsoft design exercise</div>
                <div className='m-top-24' style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={profile} style={{ width: '48px' }} className='m-right-16'></img>
                    <div>
                        <div className='p2'>Menghui Hu</div>
                        <div className='p2 text-grey'>Apr 14, 2022 · 1 week</div>
                    </div>
                </div>
            </div>
            <div className='con-1080 m-auto m-top-24'>
                <div className='p2 con-720'>The design challenge I choose is car parking problem in China. I chose this exercise, because I think it's a huge problem but people often overlook and even get used to. I find it exciting solving problems that "don't change".</div>
                <div className='p2 con-720 m-top-12'>The exercise took me a week, approximately 10 hours to finish, including writing this article.</div>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>A holistic view</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>Starting the prompt, the first thing I do is try to get a full picture of car parking system in China. So that I could focus on the right user group to find key people problems. There are different stakeholders in a city's transportation system - drivers, government departments, parking lot admin etc.</div>
                <div className='h5 con-720 m-top-16'>To start with, I decided to focus on drivers’ car parking problems.</div>
                <div className='p2 con-720 m-top-12'>In short, drivers are having a lot of problems in day to day parking situation, government departments come up with policies to solve these problems, property admin executes these policies and manages parking lots..</div>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>People research</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>At this stage, I went broad to see what are some problems drivers are having. I interviewed 2 friends, who are pretty different in terms of age, lifestyle and jobs they do comparing to me, so that I could get different perspectives. </div>
                <div className='p2 con-720 m-top-12'>Lyu Jun - a 45 year old mother in Shanghai. She is a teacher at university, she drives a lot, to work, pick up kids, go to grocery store etc.</div> 
                <div className='p2 con-720 m-top-12'></div>
                <div className='p2 con-720 m-top-12'>Can Chen - a 31 year old sales person in Shanghai. He drives a lot to in daily work to meet clients in office building, street restaurant etc.</div>
                <div className='p2 con-720 m-top-12'></div>
                <div className='h5 con-720 m-top-16'>During the interview I focused on the flowing topics and jotted down the scenarios in FigJam:</div>
                <div className='p2 con-720 m-top-12'>1. The specific scenario when they encounter parking problems.</div>
                <div className='p2 con-720'>2. What they do and how they feel before, during and after that parking experience.</div>
                <div className='p2 con-720'>3. How do they solve that parking problem in the end, and what would they do if it comes up again in the future.</div>

            </div>
            <div className='flex-between m-top-48 con-1176 m-auto'>
                <img src={research} className='border-light r-32' style={{ height: '528px' }}></img>
                <img src={research1} style={{ height: '528px' }}></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>Defining problem</div>
            <div className='con-1080 m-auto m-top-24'>
                <div className='p2 con-720'>To sort out different problems, I categorized problems in the affinity map, and vote on the problems that are really painful for drivers. So when I define if it’s real painful problem, I think about the real scenario, the people’s feeling, the current solution and the consequence.</div>
                <div className='p2 con-720 m-top-12'>So I figured there are 2 problems that is important to solve:</div>
                <div className='p2 con-720 m-top-24'><span className='h5'>1. Parking in specific locales such as hospitals, schools, grocery markets can be extremely hard and can have bad consequences.</span></div>
                <div className='p2 con-720 m-top-12'>Imagine Lyu Jun is taking his parents to hospital, she has to drive, and can’t miss the appointment. A lot of hospitals are in the central of the city, which has limited parking spaces. So a lot of times she has to make plans earlier just for driving to hospitals.</div>
                <div className='p2 con-720 m-top-24'><span className='h5'>2. Searching for space without knowing where to park is a big problem.</span></div>
                <div className='p2 con-720 m-top-12'>Imagine Can Chen is meeting a client, he drives to a place where he isn’t familiar. When he tries to find parking spots, he drives around street and sometimes even ended up driving farther. He gets really anxious when he doesn’t know when or where he is going to find a parking spot.</div>
            </div>
            <img src={affinitymap} className='con-1176 m-auto border-light r-32 m-top-48'></img>

            <div className='con-1080 flex-between m-auto m-top-144' style={{ alignItems: 'center' }}>
                <div style={{ width: '552px' }}>
                    <div className='h1'>Problem statement</div>
                    <div className='problem-statement m-top-24'>Parking space is extremely limited at certain public locales at peak time, such as hospitals, grocery market, schools etc. it gets worse when visitors don’t know where else to park.</div>
                </div>
                <img src={problem1} style={{ width: '480px' }}></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>Context & Assumptions</div>
            <div className='con-1080 p2 m-auto m-top-24'>
                <div className='p2 con-720'>Before coming to solutions, I thought about the reasons down the line, what is causing this problem? And what are the areas I could explore? The following assumptions are based on previous research study and analysis:</div>
                <div className='con-720 m-top-24'>1. There are more cars than parking spots around these specific locales.</div>
                <div className='con-720 m-top-12'>2. The parking lot information is not clear or transparent to the driver.</div>
                <div className='con-720 m-top-12'>3. Different locales have different peak time, the situation is only worst at the peak time.</div>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>Brainstorming ideas</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>According to the problem and context, I started brainstorming ideas. Because of the limited time I have, I used Crazy 8 to help coming up ideas as quickly as possible and then evaluated the pros and cons of the solutions.</div>
            </div>
            <img src={brainstorm} className='con-1080 m-auto r-32 m-top-48'></img>

            <div className='con-1080 m-top-144 m-auto' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <div className='h1' style={{ width: '480px' }}>Solution - Parking spot sharing</div>
                    <div className='p2 m-top-24' style={{ width: '528px' }}>
                        <div className=''>At these locales, the parking peak time is different. For example, when a lot of people are going to hospital in day time, in the apartment parking lot nearby, there are plenty empty personal parking spaces, because people go out to work. The idea is to share these personal parking spaces at apartments, office buildings when they are not used.</div>
                        <div className='m-top-12'>1. It creates more parking spots dynamicly.</div>
                        <div className=''>2. It provides clear parking information nearby.</div>
                        <div className='m-top-12'>The service should be a mobile first experience, because people are mostly likely using this it when they are driving and using it on a portable device.</div>
                        
                        {/* <div className='m-top-16 h5'>Problems it solve</div> */}
                        {/* <div className='m-top-12'>
                            1. It creates more parking spots dynamicly.
                        </div>
                        <div className=''>
                            2. It provides clear parking information nearby.
                        </div>
                        <div className=''>
                            3. It reasonably make use of current parking resource.
                        </div>
                        <div className='m-top-12'>
                            The service should be a mobile first experience, because people are mostly likely using this it when they are driving and using it on a portable device.
                        </div> */}
                    </div>
                </div>
                <div style={{ width: '696px', height: '844px', position: 'relative', marginLeft: '48px' }}>
                    <img src={hifi1} className='s-3' style={{ width: '390px' }}></img>
                    <img src={hifi2} className='s-3' style={{ width: '390px', position: 'absolute', right: '0', top: '0' }}></img>
                </div>
            </div>

            {/* <div className='con-1080 m-auto h1 m-top-144'>Problems it solve</div>
            <div className='con-1080 p2 m-auto m-top-24'>
                <div className='p2 con-720'>Before coming to solutions, I thought about the reasons down the line, what is causing this problem? And what are the areas I could explore? The following assumptions are based on previous research study and analysis:</div>
                <div className='con-720 m-top-24'>1. It creates more parking spots dynamicly.</div>
                <div className='con-720 m-top-12'>2. It provides clear parking information nearby.</div>
                <div className='con-720 m-top-12'>3. Different locales have different peak time, the situation is only worst at the peak time.</div>
            </div> */}

            <div className='con-1080 m-auto h1 m-top-144'>User story</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='h5 con-720'>Driver / parking spot owner / parking lot admin</div>
                <div className='p2 con-720 m-top-12'>Because of limited time and data. User story is a good way to help me design for different users and scenarios. The user story is based on previous interview insights and my assumptions.</div>
            </div>
            <img src={userstory} className='con-1080 m-auto m-top-48'></img>


            <div className='con-1080 m-auto h1 m-top-144'>Onboarding</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>There are 3 major types of users in the experience. The driver comes to find a parking spot most likely when he get the destination and couldn’t find parking spots nearby. The parking spot owner comes to rent out his spot at the time he isn’t user. The property owner who comes to manage parking spot in the backend admin platform. (Because of limited time, I did not draw out the experience for the admin).</div>
                <div className='p2 con-720 m-top-12'>During onboarding the app takes user with different purpose through different user journey and help them set up.</div>
                <div className='p2 con-720 m-top-48 text-grey' style={{ fontStyle: 'italic' }}>(Scroll → to view the entire wireframe)</div>
            </div>
            <div className='exercise-wireframe con-1176 m-top-12 m-auto'>
                <img src={onboarding} style={{ height: '647px' }}></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>Find & park</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>As a driver, he is most likely using the service when he arrives and couldn’t find parking spot or he knows it’s going to be hard to park and search ahead before going. So the app allows user to search nearby, by destination and schedule. When app matches user with the best spot by parking duration, and fees range. There are 4 steps when the user goes to parking:</div>
                <div className='p2 con-720 m-top-12'><span className='span-medium'>1. Drive to the parking lot </span>- The app connects user’s car plate number to the parking lot ETC(electronic Toll Collection System), so the user should drive in when he arrives.</div>
                <div className='p2 con-720 m-top-12'><span className='span-medium'>2. Park at the parking lot </span>- Direct to a specific parking spot is hard, so the user just has to find any spot with our parking signs that are lighted, and park there.</div>
                <div className='p2 con-720 m-top-12'><span className='span-medium'>3. Parked </span>- Now the user is parked, and he is notified the maximum time he could park at this spot.</div>
                <div className='p2 con-720 m-top-12'><span className='span-medium'>4. Finish parking </span >- When the user finishes parking, he could just drive out and the ECT will send the parking bill to him through the app.</div>
                <div className='p2 con-720 m-top-48 text-grey' style={{ fontStyle: 'italic' }}>(Scroll → to view the entire wireframe)</div>
            </div>
            <div className='exercise-wireframe con-1176 m-top-12 m-auto'>
                <img src={findspot} style={{ height: '647px' }}></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>Rent out parking psot</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>In renting out experience, the app takes user’s request, and gathers user’s preferred renting time. Then a specialist will come to install the equipment for the user, and set it up for renting. The owner can keep track and manage his renting spot anytime in app.</div>
                <div className='p2 con-720 m-top-48 text-grey' style={{ fontStyle: 'italic' }}>(Scroll → to view the entire wireframe)</div>
            </div>
            <div className='exercise-wireframe con-1176 m-top-12 m-auto'>
                <img src={rentspot} style={{ height: '647px' }}></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>High fidelity</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>The high fidelity is designed for comprehension. So I chose screens in find and rent experience to demonstrate. In the find experience, the idea is to convey clear information about the parking situation near the destination, and prompt user with the major action to find a spot nearby. In the rent experience, the idea is to show clear status of the renting spot, and quick actions and changes user could take to give a peace of mind.</div>
            </div>
            <div className='con-1080 m-top-48 m-auto' style={{ display: 'flex' }}>
                <img src={hifi1} style={{ width: '390px', marginRight: '96px' }} className='s-3'></img>
                <img src={hifi2} style={{ width: '390px' }} className='s-3'></img>
            </div>

            <div className='con-1080 m-auto h1 m-top-144'>What more</div>
            <div className='con-1080 m-auto h1 m-top-24'>
                <div className='p2 con-720'>When designing the experience, I found risks in multiple touch points. if I had more time, I think they are important to be handled, if I want to deliver an intuitive service.</div>
                <div className='p2 con-720 m-top-12'>1. What happens when they the driver parks overtime?</div>
                <div className='p2 con-720 m-top-12'>2. How might we handle safety and privacy concerns and convey that message to the audience?</div>
                <div className='p2 con-720 m-top-12 m-bottom-48'>3. How might we make the equipment and support service more sustainable?</div>
            </div>

        </div>
    )
}

export default Microsoft;