import { useEffect, useRef, useState} from 'react';
import Button from '../components/ui/Button'
import Confettisvg from '../components/ui/Confettisvg'

const Confetti = () => {
    const [isConfetti, setIsConfetti] = useState(true);

    useEffect(()=>{
        console.log('effectcalled')
        if (isConfetti) {
            setTimeout(() => {
               setIsConfetti(false) 
            }, 5000);
            console.log('removed')
        } else {
            setTimeout(() => {
                setIsConfetti(true)
            }, 2000)
            console.log('added')
        }
    }, [isConfetti])

    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>Confetti</div>
            <div className='p2 text-grey m-top-24 con-720 text-center'>The confetti animation is designed for celebratory moments, when the user completes a milestone. The random elements in SVG are generated by Python. The code was shipped on ibi app.</div>
            <Button className='m-top-24' href='https://codepen.io/menghuihu/pen/abbGZJo/32e3855bc186902eddc9dc1d3e6441a0'>View on Codepen</Button>
            <div className='con-1080 r-48 m-top-48' style={{ height: '540px', backgroundImage: 'linear-gradient(#f0f0f0, #f8f8f8)', position:'relative'}}>
                <div className='h2' style={{position:'absolute', top:'96px', left:'50%', transform:'translateX(-50%)'}}>Congrats on your first post!</div>
                {isConfetti ? (<Confettisvg></Confettisvg>) : (<div />)} 
            </div>
        </div>
    )
}

export default Confetti