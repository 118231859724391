import Button from '../components/ui/Button'
import { useEffect } from 'react';
import { useRef } from 'react';
import '../App.css'

const Bubble = () => {
    var Tau = Math.PI * 2;
    var debug = false;
    const canvasRef = useRef(null);
    const width = 1080;
    const height = 640;

    function Point(centerX, centerY, radius, angle, context) {
        this.amplitude = 8 + Math.random() * 8;
        this.speed = 0.01 + Math.random() * 0.03;
        this.context = context;
        this.centerX = centerX;
        this.centerY = centerY;
        this.radius = radius;
        this.angle = angle;
        this.t = Math.random() * Tau;
        this.draw = function () {
            this.t += this.speed;
            this.sway = Math.cos(this.t) * this.amplitude;
            this.x = Math.sin(this.angle) * (this.radius + this.sway) + this.centerX;
            this.y = Math.cos(this.angle) * (this.radius + this.sway) + this.centerY;
            if (debug) {
                this.context.fillStyle = "white";
                this.context.beginPath();
                this.context.arc(this.x, this.y, 2, 0, Tau);
                this.context.fill();
            }
        }
        return this;
    }

    function Circle(centerX, centerY, radius, numberOfPoints, color, context) {
        this.context = context
        this.centerX = centerX;
        this.centerY = centerY;
        this.radius = radius;
        this.numberOfPoints = numberOfPoints;
        this.color = color;
        this.angleIncrement = Tau / this.numberOfPoints;
        this.randomAngleShift = Math.PI / 2;
        this.Points = [];
        for (var i = 0; i < this.numberOfPoints; i++) {
            this.Points.push(new Point(this.centerX, this.centerY, this.radius, this.angleIncrement * i + this.randomAngleShift, this.context));
        }
        this.draw = function () {
            if (debug) {
                this.context.strokeStyle = "rgba(255, 0, 0, 0.3)";
                this.context.beginPath();
                this.context.moveTo(this.Points[0].x, this.Points[0].y);
                for (var i = 1; i < this.Points.length; i++) {
                    this.context.lineTo(this.Points[i].x, this.Points[i].y);
                }
                this.context.lineTo(this.Points[0].x, this.Points[0].y);
                this.context.stroke();
            }
            for (var i = 0; i < this.Points.length; i++) {
                this.Points[i].draw();
            }
            // context.strokeStyle = this.color;
            this.context.fillStyle = this.color;
            this.context.beginPath();
            let midX, midY, firstMidX, firstMidY
            for (var i = 0; i < this.Points.length; i++) {
                if (i < this.Points.length - 1) {
                    midX = (this.Points[i].x + this.Points[i + 1].x) / 2;
                    midY = (this.Points[i].y + this.Points[i + 1].y) / 2;
                } else {
                    midX = (this.Points[i].x + this.Points[0].x) / 2;
                    midY = (this.Points[i].y + this.Points[0].y) / 2;
                }
                if (i == 0) {
                    this.context.moveTo(midX, midY);
                    firstMidX = midX;
                    firstMidY = midY;
                } else {
                    context.quadraticCurveTo(this.Points[i].x, this.Points[i].y, midX, midY);
                }
            }
            context.quadraticCurveTo(this.Points[0].x, this.Points[0].y, firstMidX, firstMidY);
            context.fill();
        }
        return this;
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = width * 2;
        canvas.height = height * 2;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}PX`;
        const context = canvas.getContext('2d');
        context.scale(2, 2);

        let animationFrameId

        const bubble1 = new Circle(width / 2, height / 2, 200, 8, 'red', context)
        const bubble2 = new Circle(width / 2, height / 2, 200, 8, 'blue', context)
        const bubble3 = new Circle(width / 2, height / 2, 200, 8, 'yellow', context)



        const render = () => {
            context.save();
            context.clearRect(0, 0, width, height);
            context.globalCompositeOperation = "lighten";

            bubble1.draw();
            bubble2.draw();
            bubble3.draw();

            context.restore();
            animationFrameId = window.requestAnimationFrame(render);
        }
        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [])

    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>"Bubble" animation</div>
            <div className='p2 text-grey m-top-24 con-720 text-center'>The animation of was inspired by bubbles in nature, to create a smooth and fluid feeling. The setup of animation allows it to be easily customized. The code is shipped in Minutes for background touch.</div>
            <Button className='m-top-24' href='https://codepen.io/menghuihu/pen/qYoOMb/f5181bee65c1a0c8dcf039754596b0f1'>View on Codepen</Button>
            <canvas ref={canvasRef} style={{ backgroundColor: '#323232' }} className='m-top-48 r-48'></canvas>
        </div>   
    )
}

export default Bubble;