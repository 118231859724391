import Button from '../components/ui/Button'
import tool from '../assets/darkmode/tool.mp4'
import '../App.js'

const Plugin = () => {
    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>Dark mode switch Figma plugin</div>
            <div className='p2 text-grey m-top-24 con-720 text-center'>The dark mode swtich plugin <span className=''>switches Figma design between light and dark</span> in a snap. It greatly improve the efficiency for designers to adapt design for different themes.</div>
            <Button className='m-top-24' href='https://github.com/footballsir/dmscript'>View on Github</Button>
            <div className='m-top-48 con-1080' style={{borderRadius: '48px', padding:'48px 0', backgroundColor:'#e8e8e8', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <video width="972" height="540" loop autoPlay muted>
                    <source src={tool} type="video/mp4"/>
                </video>
            </div>
        </div>
    )
}

export default Plugin