import '../App.css'

const ProjectHeader = (props) => {
    return (
        <div className='m-bottom-24 m-top-96 m-left-40'>
            <img src={props.img} style={{ height: '24px' }}></img>
            {/* <div style={{ width: '720px' }} className='p2 m-top-16'><span className='span-bold'>{props.title}</span>{props.children}</div> */}
            <div className='p2 m-top-16 con-720' style={{fontFamily: "'GT Walsheim Regular', Helvetica, Arial, sans-serif"}}><span className='span-bold' style={{fontFamily: "'GT Walsheim Bold', Helvetica, Arial, sans-serif"}}>{props.title}</span>{props.children}</div>
        </div>
    )
}

export default ProjectHeader;