import fabvideo from '../assets/fab/fab.mp4'
import Button from '../components/ui/Button'

const Fab = () => {
    return (
        <div className='project-hero'>
            <div className='h1 m-top-64'>Expandable floating action button</div>
            <div className='p2 text-grey m-top-16 con-720 text-center'>Floating action button is great, but sometimes it doesn't convey enough information. With this the expandable floating action button, the user gets the best of real estate for content display and the convinience of informative texts button. The design was shipped on ibi app.</div>
            <Button className='m-top-24' href='https://codepen.io/menghuihu/pen/dgzgmr/49adf381ead46f5ea92ef01e68f80957?editors=0110'>View the code</Button>
            <div className='m-top-48 con-1080' style={{borderRadius: '48px', padding:'48px 0', backgroundColor:'#f8f8f8', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <video width="336" height="600" loop autoPlay muted className='s-1'>
                    <source src={fabvideo} type="video/mp4"/>
                </video>
            </div>
        </div>
    )
}

export default Fab