import Button from '../components/ui/Button'
import ibiimg from '../assets/ibi/ibi.png'

const Ibi = () => {
    return (
        <div className='background-g1' style={{width:'100%', paddingTop:'48px', paddingBottom:'48px'}}>
            <div className='con-1200 m-auto flex-between' style={{alignItems:'center'}}>
                <div style={{width:'288px'}}>
                    <div className='h1'>ibi - smart photo manager</div>
                    <div className='p2 m-top-24 text-grey'>ibi is a smart photo manager. With network attached storage, it is like a personal cloud for your photos and videos.</div>
                    <Button className='m-top-24' href='http://meetibi.com/'>Visit ibi website</Button>
                </div>
                <div style={{width:'864px'}}>
                    <img style={{height: '685px'}} src={ibiimg}></img>
                </div>
            </div>
        </div>
    )
}

export default Ibi;