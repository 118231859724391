import Tag from './ui/Tag'

const Project = (props) => {
    const tag = props.tag.map((tag)=>{
        return <Tag key={Math.random()} className='m-right-8'>{tag}</Tag>
    })

    return (
        <div className='project-tile tile'>
            <div>
                <img src={props.img} style={{width: '496px', borderRadius: '32px'}}></img>
                <div className='caption1 m-left-24 m-right-24 text-grey m-top-24'>{props.caption}</div>
                <div className='h3 m-left-24 m-right-24 m-top-12 default-color'>{props.title}</div>
            </div>
            <div className='m-left-24 m-right-24 m-bottom-24 default-color'>{tag}</div>
        </div>
    )
}

export default Project;