import hero from '../assets/autodesk/autodeskvideo.mp4'
import shell from '../assets/autodesk/shell.png'
import problem from '../assets/autodesk/problem.png'
import redesign from '../assets/autodesk/redesign.png'
import interfaceproblem from '../assets/autodesk/interface.png'
import userjourney from '../assets/autodesk/userjourney.png'
import designSprint1 from '../assets/autodesk/designsprint1.png'
import designSprint2 from '../assets/autodesk/designsprint2.png'
import nps from '../assets/autodesk/nps.png'
import jobstory from '../assets/autodesk/jobstory.png'
import data1 from '../assets/autodesk/data1.png'
import data2 from '../assets/autodesk/data2.png'
import data3 from '../assets/autodesk/data3.png'
import data4 from '../assets/autodesk/data4.png'
import measure from '../assets/autodesk/a-measure.mp4'
import mobile1 from '../assets/autodesk/mobile1.png'
import mobile2 from '../assets/autodesk/mobile2.png'
import exportoption from '../assets/autodesk/export.png'
import wireframe from '../assets/autodesk/wireframe.png'

import InlineLink from '../components/ui/InlineLink'
import Button from '../components/ui/Button'
import Caption from '../components/ui/Caption'

const Autodesk = () => {
    return (
        <div className='project-container'>
            <div className='project-hero background-g1'>
                <div className='h1 m-top-64'>Bytedance Mniutes</div>
                <div className='p1 text-grey m-top-16 con-960 text-center'>The first online 3D model collaboration platform for over 50 types of design files</div>
                <Button className='m-top-16' href='https://viewer.autodesk.com/'>Go to Viewer</Button>

                <div className='con-1080 m-top-48 m-bottom-64 s-1 r-8' style={{ position: 'relative' }}>
                    <img src={shell} className='con-1080 r-8'></img>
                    <div className='con-1080' style={{ position: 'absolute', top: '76px', overflow: 'hidden', zIndex: '1', height: '568px' }}>
                        <video width="1200" height="600" className='m-top-48 m-bottom-64' loop autoPlay muted style={{ marginTop: '-24px' }}>
                            <source src={hero} type="video/mp4" />
                        </video>
                    </div>
                </div>

            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>A snapshot</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                    <div className='con-516'>
                        <Caption className='m-top-24'>My role</Caption>
                        <div className='h4 m-top-16'>Lead designer on Autodesk Drive</div>
                        <div className='p2 text-grey m-top-8'>Responsible for the over all product design such as hands on design craft, design roadmap, design review etc.</div>
                        <div className='h4 m-top-16'>Built job story library</div>
                        <div className='p2 text-grey m-top-8'>Established job story library of 200+ records with PMs and engineers, lead to development of several cores pillars.</div>
                    </div>
                    <div className='con-516'>
                        <Caption className='m-top-24'>Product milestones</Caption>
                        <div className='h4 m-top-16'>3M+ user acquisition</div>
                        <div className='p2 text-grey m-top-8'>In the first half of 2020, Autodesk Viewer has 3M+ users globally.</div>
                        <div className='h4 m-top-16'>Helped with subscription business model</div>
                        <div className='p2 text-grey m-top-8'>The add on value of Autodesk drive platform, helped Autodesk products to pivot from license to subscription.</div>
                    </div>
                </div>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Challenge working with 3D models</div>
            </div>

            <div className='flex-between con-1176 m-top-48 m-auto'>
                <div style={{ width: '432px', height: '544px', padding: '48px' }} className='background-g2 r-48'>
                    <div className='h4'>Problem accessing designs</div>
                    <div className='p2 text-grey m-top-8'>Key stakeholders such as clients and project managers don't have or don't know how to use tools like CAD to view designs.</div>
                    <div className='h4 m-top-24'>Problem exporting designs</div>
                    <div className='p2 text-grey m-top-8'>Archetects are exporting tons of PDFs to example as small change on the 3D model.</div>
                </div>
                <div style={{ width: '720px', height: '544px', padding: '48px' }} className='background-g2 r-48'>
                    <img src={problem} style={{ width: '608px', height: '448px' }} loading='lazy'></img>
                </div>
            </div>

            <div className='m-top-144 fullsize-greytowhite'>
                <div className='con-1080 m-auto'>
                    <div className='h1'>Redesign Viewer as a standalone app</div>
                    <div className='con-720 text-grey p2 m-top-24'>Before viewer, there are 2 ways to solve this problem, one is to use BIM 360 as a project management app, the other is to use Forge cloud API. We decided to provide users a easy way by redesigning the viewer feature to a standalone product for a broader user audience.</div>
                    <img src={redesign} style={{ width: '1080px' }} className='m-top-24' loading='lazy'></img>
                </div>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '336px' }}>
                        <div className='h3'>Rebuild user journey</div>
                        <div className='p2 text-grey m-top-16'>We mapped out the end to end user journey for viewer to help think about the experience for two major roles, the creator and the viewer.</div>
                    </div>
                    <img src={userjourney} style={{ width: '720px' }} className='r-48' loading='lazy'></img>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='m-top-144'>
                    <img src={interfaceproblem} style={{ width: '720px' }} className='r-48' loading='lazy'></img>

                    <div style={{ width: '336px' }}>
                        <div className='h3'>Interface Review</div>
                        <div className='p2 text-grey m-top-16'>We gathered feedbacks from our community to indentify exisitng problems to solve during redesign.</div>
                    </div>
                </div>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Design sprint with stakeholders</div>
                <div className='p2 text-grey m-top-24 con-720'>To make sure that we as a team caught all the major uses cases, context and possible directions, I conducted sessions of design sprint with PMs and engineers. In the end we summarized down to several pillars we need to build and specific problems we want to solve.</div>
            </div>
            <div className='con-1176 m-auto m-top-24 flex-between'>
                <img src={designSprint1} style={{ width: '720px', height: '400px' }} className='r-48' loading='lazy'></img>
                <img src={designSprint2} style={{ width: '432px', height: '400px' }} className='r-48' loading='lazy'></img>
            </div>


            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Viewer experience</div>
                <div className='p2 text-grey m-top-24 con-720'>There are two major roles for a the Autodesk Viewer product. For creators, they open viewer in the browser, upload the files from local or cloud and then share the file with clients. As for the consumer, when they get the shared files, they view the files in detiail, take measurements and comment on the file etc.</div>
            </div>

            <div className='con-1176 m-auto m-top-24 r-48' style={{ background: 'linear-gradient(75.22deg, rgba(47, 106, 241, 0.75) -0.66%, rgba(47, 106, 241, 0.35) 94.54%)', padding: '40px 48px' }}>
                <div style={{ position: 'relative' }} className='con-1080 m-auto r-8 '>
                    <iframe width="1081" height="640" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FP0cNhtNEg2YzEYkLk0iInR%2FPrototypes%3Fpage-id%3D224%253A13595%26node-id%3D224%253A14224%26viewport%3D245%252C48%252C0.19%26scaling%3Dmin-zoom%26starting-point-node-id%3D224%253A14224%26show-proto-sidebar%3D1%26hide-ui%3D1" allowfullscreen className='r-8' style={{ border: 'none' }}></iframe>
                    <div style={{ position: 'absolute', height: '640px', width: '1081px', border: '1px solid #d4d4d4', zIndex: '1', top: '0px', left: '0px', pointerEvents: 'none' }} className='r-8'></div>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#141414', padding: '64px 0' }} className='m-top-144'>
                <div className='con-1080 m-auto'>
                    <div className='h1' style={{ color: 'white' }}>Data reference</div>
                    <div className='con-1080 m-top-48 flex-between'>
                        <img src={data1} style={{ width: '520px' }} loading='lazy'></img>
                        <img src={data2} style={{ width: '520px' }} loading='lazy'></img>
                    </div>
                    <div className='con-1080 m-top-48 flex-between'>
                        <img src={data3} style={{ width: '520px' }} loading='lazy'></img>
                        <img src={data4} style={{ width: '520px' }} loading='lazy'></img>
                    </div>
                </div>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Qualitative insights</div>
                <div className='h3 m-top-24'>NPS measurement</div>
                <div className='p2 text-grey m-top-16 con-720'>NPS system not only provides data reference for our product experience quality, but also give evidence of how people are using view. We integrated NPS with Google AI platform, so that it automatically sort out the top feedbacks. We can easily find converison, speed, on top of the list.</div>
            </div>
            <div className='con-1176 m-auto m-top-24 r-48 background-g1' style={{ padding: '40px 48px 0 48px' }}>
                <img src={nps} className='con-1080 m-auto' loading='lazy'>
                </img>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h3'>Job story library</div>
                <div className='p2 text-grey m-top-16 con-720'>As a business product, one challenge for designing Viewer is to really understand the specific user scenario for prosumers. So I collaborated with the team to establish the job story library, it not only summarize user needs on a high level, but also points specific user scenarios.</div>
            </div>
            <div className='con-1176 m-auto m-top-24 r-48' style={{ background: 'linear-gradient(75.22deg, rgba(47, 106, 241, 0.75) -0.66%, rgba(47, 106, 241, 0.35) 94.54%)', padding: '40px 48px 0 48px' }}>
                <img src={jobstory} className='con-1080 m-auto' loading='lazy'>
                </img>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Product design</div>
                <div className='h3 m-top-48'>Scalability</div>
                <div className='p2 text-grey m-top-16 con-720'>With MAU being the Northstar metrics, we designed reponsive interface for mobile, pad and desktop. On the mobile device the experiences is focused on viewing design files on the go.</div>
            </div>
            <div className='m-top-48 r-48 background-g2' style={{ width: '100%', padding: '48px 0', display: 'flex', justifyContent: 'center' }}>
                <img src={mobile1} className='s-1' style={{ width: '375px', marginRight: '96px' }} loading='lazy'></img>
                <img src={mobile2} className='s-1' style={{ width: '375px' }} loading='lazy'></img>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h3'>Model analysis</div>
                <div className='p2 text-grey m-top-16 con-720'>We designed tools for viewers to really understand the model. In the example of measure, the cursor intelligently capture the anchor point of the model and helps the user take measurements easily</div>
            </div>
            <div className='con-1176 m-auto m-top-24 r-48' style={{ background: 'linear-gradient(75.22deg, rgba(47, 106, 241, 0.75) -0.66%, rgba(47, 106, 241, 0.35) 94.54%)', padding: '40px 48px 0 48px' }}>
                <video width="1080" height="640" loop autoPlay muted style={{marginBottom:'-4px'}}>
                    <source src={measure} type="video/mp4" />
                </video>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h3'>Export options</div>
                <div className='p2 text-grey m-top-16 con-720'>As we learned how people uses Autodesk Viewer in their daily work. We found that exporting design images/videos from viewer helps facilitate meetings, workshops etc. We built various options for user to export design to other types of digital assets.</div>
            </div>
            <div className='con-1176 m-auto m-top-24 r-48' style={{ background: 'linear-gradient(75.22deg, rgba(47, 106, 241, 0.75) -0.66%, rgba(47, 106, 241, 0.35) 94.54%)', padding: '40px 48px 0 48px' }}>
                <img src={exportoption} className='con-1080 m-auto' loading='lazy'>
                </img>
            </div>

            <div className='con-1080 m-auto m-top-144'>
                <div className='h3'>Design for comprehension</div>
                <div className='p2 text-grey m-top-16 con-720'>My design process of these sorts of mainly mechanical features is usually focused on just that: mechanics, ergonomics and logic rather than specific interactions or UI.</div>
            </div>
            <img src={wireframe} className='con-1280 m-auto m-top-24' loading='lazy'>
            </img>


            <div className='con-1080 m-auto m-top-144'>
                <div className='h1'>Experiments & examples</div>
                <div className='p2 m-top-48'><span className='span-bold'>AR experiment: </span><InlineLink href='https://menghuih.notion.site/Viewer-AR-Demo-40321ad358e449ca8d5c60c49025222b'>Put model in reality</InlineLink></div>
                <div className='p2 m-top-16'><span className='span-bold'>File example: </span><InlineLink href='https://autode.sk/3uocs0X'>Jet engine viewer</InlineLink></div>
            </div>
        </div>
    )
}

export default Autodesk;