import profile from '../assets/homepage/profile.png'
import Logoicon from '../components/ui/Logoicon'
import footerimg from '../assets/homepage/footer.png'
import '../App.css'

const Footer = () =>{
    return (
        <div className='footer' style={{backgroundImage: `url(${footerimg}`, backgroundSize:'cover'}}>
            <img src={profile} style={{height: '48px'}}></img>
            <div className='p2 m-top-24 m-bottom-24' style={{textAlign:'center'}}>Designed and developed by Menghui Hu in 2022<br />Find me elsewhere:</div>
            <Logoicon />
        </div>
    )
}

export default Footer;