import { useEffect } from 'react';
import { useRef } from 'react';

const CanvasBubble = (props) => {
    var Tau = Math.PI * 2;
    var debug = false;
    const canvasRef = useRef(null);
    const width = 1280;
    const height = 640;

    function Point(centerX, centerY, radius, angle, context,a,s) {
        //initial 8
        this.a = a;
        this.s = s;

        this.amplitude = this.a*(1 + Math.random());
        //initial 0.01
        this.speed = this.s*(1 + 3*Math.random());
        this.context = context;
        this.centerX = centerX;
        this.centerY = centerY;
        this.radius = radius;
        this.angle = angle;
        this.t = Math.random() * Tau;
        this.draw = function() {
            this.t += this.speed;
            this.sway = Math.cos(this.t) * this.amplitude;
            this.x = Math.sin(this.angle) * (this.radius + this.sway) + this.centerX;
            this.y = Math.cos(this.angle) * (this.radius + this.sway)  + this.centerY;
            if(debug) {
                this.context.fillStyle = "white";
                this.context.beginPath();
                this.context.arc(this.x, this.y, 2, 0, Tau);
                this.context.fill();
            }
        }
        return this;
    }

    function Circle(centerX, centerY, radius, numberOfPoints, color, context, a,s) {
        this.context = context
        this.centerX = centerX;
        this.centerY = centerY;
        this.radius = radius;
        this.numberOfPoints = numberOfPoints;
        this.a = a;
        this.s = s;
        this.color = color;
        this.angleIncrement = Tau / this.numberOfPoints;
        this.randomAngleShift = Math.random()* Tau;
        this.Points = [];
        for(var i = 0; i < this.numberOfPoints; i++){
                this.Points.push(new Point(this.centerX, this.centerY, this.radius, this.angleIncrement * i + this.randomAngleShift, this.context, this.a, this.s));
        }
        this.draw = function() {   
            if(debug) {
                this.context.strokeStyle = "rgba(255, 0, 0, 0.3)";
                this.context.beginPath();
                this.context.moveTo(this.Points[0].x,this.Points[0].y);
                for(var i = 1; i < this.Points.length; i++) {
                    this.context.lineTo(this.Points[i].x, this.Points[i].y);
                }
                this.context.lineTo(this.Points[0].x, this.Points[0].y);
                this.context.stroke();
            }
            for(var i = 0; i < this.Points.length; i++) {
                this.Points[i].draw();
            }
            // context.strokeStyle = this.color;
            // this.context.fillStyle = this.color;
            let gradient = this.context.createLinearGradient(this.centerX - this.radius, this.centerY - this.radius, this.centerX + this.radius, this.centerY + this.radius);
            gradient.addColorStop(0, this.color[0]);;
            gradient.addColorStop(1, this.color[1]);
            this.context.fillStyle = gradient;
            this.context.strokeStyle = 'rgba(0,0,0,0.16)'
            this.context.lineWidth = 1

            this.context.beginPath();
            let midX, midY, firstMidX, firstMidY
            for(var i = 0; i < this.Points.length; i++) {
                if(i < this.Points.length - 1) {
                    midX = (this.Points[i].x + this.Points[i+1].x)/2;
                    midY = (this.Points[i].y + this.Points[i+1].y)/2;
                } else {
                    midX = (this.Points[i].x + this.Points[0].x)/2;
                    midY = (this.Points[i].y + this.Points[0].y)/2;
                }
                if(i==0){
                    this.context.moveTo(midX,midY);
                    firstMidX = midX;
                    firstMidY = midY;
                } else {
                    context.quadraticCurveTo(this.Points[i].x,this.Points[i].y, midX, midY);
                }
            }
            context.quadraticCurveTo(this.Points[0].x,this.Points[0].y, firstMidX, firstMidY);
            context.fill();
            context.stroke();
        }
        return this;
    }

    useEffect(()=> {
        const canvas = canvasRef.current;
        canvas.width = width * 2;
        canvas.height = height * 2;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}PX`;
        const context = canvas.getContext('2d');
        context.scale(2,2);

        let animationFrameId;
        let center1 = [40,40, 120, 8, 6, 0.008]

        let center2 = [width-80, 40, 120, 8, 6, 0.008]

        let center3 = [width-60, height-60, 200, 8, 8, 0.005]

        let center4 = [40, height-40, 80, 8, 4, 0.008]

        
        

        const bubble1a = new Circle(80, 20, 90, 8, ['#FF1B4C','#FF90A8'], context, 3, 0.01)
        const bubble1b = new Circle(-10, 110, 90, 8, ['#9C79FF','#D7CDFF'], context, 3, 0.01)

        const bubble2a = new Circle(width-80, 20, 80, 8, ['#F2F3B7','#C9CC2D'], context, 3,0.01)
        const bubble2b = new Circle(width-20, 80, 80, 8, ['#F2F3B7','#C9CC2D'], context, 3,0.01)

        // const bubble2b = new Circle(center2[0], center2[1], center2[2], center2[3], ['#f8f8f8','#f0f0f0'], context,center2[4],center2[5])


        const bubble3a = new Circle(width-80, height-40, 120, 8, ['#FF90A8','#FF0037'], context, 3, 0.01)
        const bubble3b = new Circle(width-10, height-160, 80, 8, ['#C9CC2D','#F7F9BC'], context, 3, 0.01)

        // const bubble4a = new Circle(center4[0], center4[1], center4[2], center4[3], ['#fafafa','#f0f0f0'], context, center4[4], center4[5])



        const render = () => {
            context.save();
            context.clearRect(0, 0, width, height);
            context.globalCompositeOperation = "lighter";
            bubble1a.draw()
            bubble1b.draw()

            bubble2a.draw()
            bubble2b.draw()

            bubble3a.draw()
            bubble3b.draw()

            // bubble4.draw()
            // bubble4a.draw()

            context.restore();
            animationFrameId = window.requestAnimationFrame(render);
        }
        render()
        
        return () => {
          window.cancelAnimationFrame(animationFrameId)
        }
    },[])

    return (
        <canvas ref={canvasRef} {...props}></canvas>
    )
}

export default CanvasBubble;